ion-modal.voucherPage {
  display: flex;
  flex-direction: column;
  --min-width: 100%;
  --min-height: 100%;
}
.voucherMain {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}
.voucherMain .title {
  height: 48px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background: #000000;
  margin-bottom: 8px;
}
.voucherMain .title > div {
  flex: 1;
  height: 48px;
  display: flex;
  align-items: center;
}
.voucherMain .title > div:first-child {
  padding-left: 16px;
}
.voucherMain .title span {
  flex: 7;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.voucherMain .nav {
  width: 100%;
  padding: 0 16px;
  margin-bottom: 24px;
}
.voucherMain .nav ul {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #394656;
}
.voucherMain .nav ul li {
  color: #a6a9b8;
  font-family: Open;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}
.voucherMain .nav ul li div {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0;
  height: 2px;
  background: #9c94ff;
  transition: all 0.3s;
}
.voucherMain .nav ul .act {
  color: #9c94ff;
}
.voucherMain .nav ul .act > div {
  width: 100%;
}
.voucherMain .main {
  flex: 1;
  overflow-y: scroll;
  padding: 0 16px;
}
.voucherMain .main .dataNone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 85%;
}
.voucherMain .main .dataNone .dataNoneImg {
  width: 155px;
  height: 215px;
}
.voucherMain .main .dataNone p {
  font-family: Open;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #9b9fae;
  margin-top: 30px;
}
.voucherMain .main ul {
  width: 100%;
}
.voucherMain .main ul li {
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background: #1e232d;
  border-radius: 12px;
  overflow: hidden;
}
.voucherMain .main ul li:last-child {
  margin-bottom: 0;
}
.voucherMain .main ul li .moneyInfo {
  flex-shrink: 0;
  width: 74px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.voucherMain .main ul li .moneyInfo p {
  color: #9c94ff;
  font-family: Open;
  font-size: 14px;
  font-weight: 400;
}
.voucherMain .main ul li .moneyInfo span {
  color: #9c94ff;
  font-family: Open;
  font-size: 32px;
  font-weight: 600;
}
.voucherMain .main ul li .moneyInfo .from {
  position: absolute;
  bottom: 10px;
  padding: 2px 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(252, 203, 129, 0.05);
  color: #fccb81;
  font-family: Open;
  font-size: 11px;
  font-weight: 400;
}
.voucherMain .main ul li .moneyInfo .to {
  position: absolute;
  bottom: 10px;
  padding: 2px 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(252, 129, 225, 0.05);
  color: #fc81e1;
  font-family: Open;
  font-size: 11px;
  font-weight: 400;
}
.voucherMain .main ul li .circle {
  width: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.voucherMain .main ul li .circle .top,
.voucherMain .main ul li .circle .bottom {
  position: absolute;
  left: -4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #101a25;
}
.voucherMain .main ul li .circle .top {
  top: -12px;
}
.voucherMain .main ul li .circle .bottom {
  bottom: -12px;
}
.voucherMain .main ul li .circle .border {
  width: 0;
  height: 60%;
  border: 1px dashed #4a4863;
}
.voucherMain .main ul li .infoActs {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 12px;
}
.voucherMain .main ul li .infoActs .userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.voucherMain .main ul li .infoActs .userInfo p {
  color: #fff;
  font-family: Open;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}
.voucherMain .main ul li .infoActs .userInfo p img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
}
.voucherMain .main ul li .infoActs .userInfo span {
  color: #979797;
  font-family: Open;
  font-size: 12px;
  font-weight: 400;
}
.voucherMain .main ul li .infoActs .Received {
  position: absolute;
  right: 17px;
  bottom: 8px;
  display: flex;
  align-items: center;
}
.voucherMain .main ul li .infoActs .Received > div {
  width: 46px;
  height: 28px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Open;
  font-size: 12px;
  font-weight: 400;
}
.voucherMain .main ul li .infoActs .Received .Reject {
  color: #9c94ff;
  margin-right: 8px;
}
.voucherMain .main ul li .infoActs .Received .Accept {
  background: #9c94ff;
  color: #2d323e;
}
.voucherMain .main ul li .infoActs .Sent {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 14px;
}
.voucherMain .main ul li .infoActs .Sent .status {
  padding: 4px 8px;
  border-radius: 19px;
  font-family: Open;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid;
}
.voucherMain .main ul li .infoActs .Sent .act {
  width: 58px;
  height: 28px;
  border-radius: 6px;
  background: #9c94ff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2d323e;
  font-family: Open;
  font-size: 12px;
  font-weight: 400;
}
