.button {
  position: relative;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans';
  margin-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.2px;
  height: 48px;
  color: #9C94FF;
  background-color: #101A25;
  border: 1px solid #9C94FF;
  width: 100%;
}
