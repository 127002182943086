.header_wrapper {
  padding: 0 16px;
  /* background-color: #000; */
}
.header {
  display: flex;
  height: 48px;
  align-items: center;

  justify-content: space-between;
  position: relative;
}
.header h1 {
  color: var(--text-primary, #fff);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}
.header .title {
  display: flex;
  height: 100%;
  width: 100%;
  z-index: -1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  flex: 1;
  text-align: center;
  /* background-color: #000; */
}

.header .title span {
  color: var(--text-seconday, #ccc);
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.header > span {
  color: var(--main-main, #9c94ff);
  text-align: right;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.2px;
}
.wrapper {
  height: 100%;
}
.refer {
  padding: 30px 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.refer p {
  margin-top: 16px;
  text-align: center;
  color: var(--text-primary, #fff);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
}
.refer_wrapper {
  position: relative;
  height: 100%;
}
.bottom_wrapper {
  z-index: 999;
  position: fixed;
  bottom: 40px;
  width: calc(100% - 32px)
}
.modal_wrapper {
  top: 100px !important;
}

/* selected friends */
.select_friends {
  height: 100%;
  position: relative;
}
.selected_wrapper {
  margin: 16px 0;
}
.search_bar {
  padding: 0;
  margin-bottom: 10px;
}
.selected_wrapper ul {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.selected_wrapper ul li {
  display: flex;
  flex-direction: column;
  width: 60px;
}
.selected_wrapper ul li div {
  position: relative;
  width: 100%;
  height: 60px;
  margin-bottom: 5px;
}
.selected_wrapper ul li span {
  color: var(--text-primary, #fff);
  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.selected_wrapper ul li div img {
  border-radius: 50%;
  overflow: hidden;
  object-fit: contain;
  object-position: center center;
}
.selected_wrapper ul li div > svg {
  cursor: pointer;
  border-radius: 24px;
  background: var(--background-bg-3, #434958);
  width: 16px;
  height: 16px;
  z-index: 3;
  position: absolute;
  right: 0;
  top: 0;
}
.select_list {
}
.select_list ul {
  padding-bottom: 88px;
}
.select_list ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  margin-bottom: 8px;
  cursor: pointer;
}
.select_list ul li img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center center;
  margin-right: 16px;
}
.select_list ul li .select_txt {
  flex: 1;
  border-bottom: 1px solid var(--background-divider, #334663);
  display: flex;
  height: 100%;
  align-items: center;
}
.select_list ul li ion-checkbox {
  margin-right: 8px;
  --size: 24px;
  --background-checked: #9c94ff;
}
.select_txt p {
  color: var(--text-primary, #fff);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.no_friends {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 120px;
}
.no_friends img {
  margin-top: 60px;
}
.no_friends p {
  width: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  color: #a6a9b8;
  margin-top: 25px;
}

/* share */

.share {
  width: 100%;
}
.share_header {
  padding: 0 16px;
  height: 48px;
  display: flex;
  align-items: center;
  background-color: #101a25;
}
.share_wrapper {
  position: relative;
  width: 100%;
}
.share_wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 15px 15px 0 0;
}
.circle_line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: -16px;
}
.circle_line > div {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #101a25;
}
.circle_line > div:first-child {
  margin-left: -16px;
  width: 32px;
  height: 32px;
}
.circle_line > div:last-child {
  width: 32px;
  height: 32px;
  margin-right: -16px;
}
.jackpot {
  width: 100%;
  position: absolute;
  left: 0;
  top: 10%;
  z-index: 9;
  text-align: center;
}
.jackpot > span {
  color: var(--text-primary, #fff);
  text-align: center;
  text-shadow: 0px 0px 32.54237365722656px 0px rgba(0, 0, 0, 0.1);
  font-family: Open Sans;
  font-size: 20.595px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.jackpot > p {
  color: var(--text-primary, #fff);
  text-align: center;
  text-shadow: 0px 0px 32.54237365722656px 0px rgba(0, 0, 0, 0.1);
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 76px;
  letter-spacing: 4px;
}
.share .ticket_bottom {
  width: 100%;
  height: 122px;
  background: #ffffff;
  border-radius: 0 0 15px 15px;
  padding-left: 22px;
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.share .ticket_share_txt span {
  font-weight: 600;
}
.share .ticket_share_txt{
  color: var(--text-black, #000);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
.share .ticket_bottom > .ticket_number {
  color: var(--text-black, #000);
  text-shadow: 0px 0px 32.54237365722656px 0px rgba(0, 0, 0, 0.1);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 26.034px; /* 185.956% */
}
.ticket_bottom > .ticket_number span{
  font-weight: 600;
}
.share_background {
  overflow: hidden;
  position: relative;
  padding: 0 5px 5px;
  border-radius: 20px;
  background: var(--main-diamond, #42e0a7);
}
.share_background::before {
  box-sizing: border-box;
  content: '';
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #101a25;
  position: absolute;
  bottom: 111px;
  left: -11px;
  z-index: 9;
  border: 5px solid var(--main-diamond, #42e0a7);
}
.share_background::after {
  box-sizing: border-box;
  content: '';
  right: -11px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #101a25;
  position: absolute;
  bottom: 111px;
  z-index: 9;
  border: 5px solid var(--main-diamond, #42e0a7);
}
.progress {
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
}
.progress p {
  text-align: center;
  color: var(--text-black, #000);
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
}
.ticket_share {
  display: flex;
}
.ticket_share ul {
  position: relative;
  display: flex;
  margin-right: 10px;
}
.ticket_share ul li {
  margin-right: -3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #fff;
}
.ticket_share ul li:nth-child(0) {
  z-index: 19;
}
.ticket_share ul li:nth-child(1) {
  z-index: 18;
}
.ticket_share ul li:nth-child(2) {
  z-index: 17;
}
.ticket_share ul li img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}
.ticket_share span {
  color: var(--text-black, #000);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
.share_bottom {
  margin-top: 20px;
  z-index: 999;
  bottom: 40px;
  width: 100%
}
.share_container {
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding: 30px 18px;
  height: 100%;
  position: relative;
}
