.upComingTop {
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #cccccc;
  padding: 0 20px 0 16px;
  position: fixed;
  top: 0;
  background: #101a25;
  z-index: 1;
  position: relative;
}

.upComingTop .pjlogo {
  width: 60px;
}

.icon {
  display: flex;
  align-items: center;
}
.isDailyActive {
  padding-right: 10px;
}
.isRingActive {
  position: relative;
  padding-left: 10px;
}

.isRingActive .purpleCircle {
  position: absolute;
  top: 0;
  right: -6px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #9c94ff;
}

.icon .dropdown {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  /* transition: all .3s; */
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.icon .setvh {
  overflow: visible;
  border-top: 2px solid #9c94ff;
  bottom: calc(-100vh + 52px);
  height: calc(100vh - 52px);
}

.dropdown .main {
  padding: 24px 16px 0;
  /* height: auto; */
  height: calc(100% - 83px);
  background: #101a25;
  overflow-y: auto;
}

.dropdown .mask {
  flex: 1;
  /* background: #101A25; */
  background: rgba(0, 0, 0, 0.5);
}

.icon .dropdown .Ringtarget {
  position: absolute;
  top: -5px;
  right: 22px;
  width: 8px;
  height: 8px;
  background: #101a25;
  transform: rotate(45deg);
  border-top: 2px solid #9c94ff;
  border-left: 2px solid #9c94ff;
}

.icon .dropdown .DailyTarget {
  position: absolute;
  top: -5px;
  right: 60px;
  width: 8px;
  height: 8px;
  background: #101a25;
  transform: rotate(45deg);
  border-top: 2px solid #9c94ff;
  border-left: 2px solid #9c94ff;
}

.icon .dropdown .main .daily .tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.icon .dropdown .main .daily .tit span {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: #ff9950;
}

.icon .dropdown .main .daily .tit span svg {
  margin-right: 4px;
}

.icon .dropdown .main .daily .checkIn {
  width: 100%;
  height: 132px;
  border: 1px solid #334663;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.icon .dropdown .main .daily .checkIn .top {
  width: 100%;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #334663;
}

.icon .dropdown .main .daily .checkIn .top .linerStart {
  width: 68px;
  height: 5px;
  background: #42e05c;
  border-radius: 12px 0 0 12px;
}

.icon .dropdown .main .daily .checkIn .top .one {
  background: #2d323e;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 0;
}

.icon .dropdown .main .daily .checkIn .top .one > div {
  width: 46px;
  height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  border-bottom: 1px solid #101a25;
}

.icon .dropdown .main .daily .checkIn .top .one > div svg {
  margin-bottom: 4px;
}

.icon .dropdown .main .daily .checkIn .top .one > span {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #cccccc;
  scale: 0.7;
}

.icon .dropdown .main .daily .checkIn .top .liner {
  width: 68px;
  height: 5px;
  background: #2d323e;
}

.icon .dropdown .main .daily .checkIn .top .liner .lining {
  height: 5px;
  border-radius: 0 12px 12px 0;
}

.icon .dropdown .main .daily .checkIn .bottom {
  flex: 1;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.2px;
  color: #cccccc;
}

.icon .dropdown .main .daily .checkIn .bottom svg {
  margin-right: 4px;
}

.icon .dropdown .main .daily .task {
  width: 100%;
  /* padding: 8px 0; */
  border: 1px solid #334663;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.icon .dropdown .main .daily .task li {
  height: 83px;
  flex: 1;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #334663;
}

.icon .dropdown .main .daily .task li:last-child {
  border: 0;
}

.icon .dropdown .main .daily .task li .left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon .dropdown .main .daily .task li .left .des {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.icon .dropdown .main .daily .task li .left .des p {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.icon .dropdown .main .daily .task li .left .des span {
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
}

.icon .dropdown .main .daily .task li .left .liner {
  width: 100%;
  height: 5px;
  background: #2d323e;
  border-radius: 12px;
}

.icon .dropdown .main .daily .task li .left .liner .lin1 {
  width: 88px;
  height: 100%;
  background: linear-gradient(270.17deg, #7190ff 0.15%, #ac8fff 99.88%);
  border-radius: 12px;
}

.icon .dropdown .main .daily .task li .left .liner .lin2 {
  width: 100%;
  height: 100%;
  background: linear-gradient(270.17deg, #5ce46a 0.15%, #2ea51b 99.88%);
  border-radius: 12px;
}

.icon .dropdown .main .daily .task li .right {
  width: 28px;
  height: 60px;
  padding: 8px 12px;
  background: #2d323e;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-left: 20px;
}

.icon .dropdown .main .daily .task li .right svg {
  flex: 1;
}

.rigthData {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #ffffff;
  padding: 4px 8px;
  border: 1px solid #334663;
  border-radius: 8px;
}

.datas {
  display: flex;
  align-items: center;
}

.datas:nth-child(2) {
  margin-left: 20px;
}

.datas svg {
  margin-right: 8px;
}

.unAuth {
  display: flex;
  align-items: center;
}

.unAuth ion-button {
  --ion-font-family: Open !important;
  width: 86px;
  height: 32px;
  border-radius: 8px;
  --border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
}

.unAuth ion-button:nth-child(1) {
  --color: #9c94ff;
  --background: transparent;
  border: 1px solid #9c94ff;
  /* margin-right: 8px; */
  margin: 0;
}

.unAuth ion-button:nth-child(2) {
  --background: #9c94ff;
  --color: #000;
}

.signUp {
  color: #ffffff;
  font-family: Open;
}

.signUp .tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
}

.signUp .tit svg {
  cursor: pointer;
}

.signUp .line {
  width: 100%;
  height: 1px;
  background-color: #334663;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
}

.nameExist {
  border: 2px solid red !important;
}

.nameExist:focus-within {
  border: 2px solid red !important;
}

.nameExist label {
  color: red !important;
}

.signUp .form .inp {
  width: 100%;
  height: 56px;
  border: 2px solid #334663;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 32px;
}

.signUp .form .inp:focus-within {
  border: 2px solid #9c94ff;
}

.signUp .form .inp label {
  position: absolute;
  top: -12px;
  left: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #cccccc;
  padding: 0 4px;
  background: #101a25;
}

.signUp .form .inp input {
  width: 100%;
  outline: none;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
}

.signUp .form .inp > svg {
  position: absolute;
  right: 16px;
  cursor: pointer;
}

.signUp .form .forget {
  margin-top: -16px;
  font-size: 12px;
  font-weight: 400;
  color: #9c94ff;
}

.signUp .form .new {
  font-size: 14px;
  font-weight: 400;
}

.signUp .form .new span {
  color: #9c94ff;
}

.signUp .form .sub {
  margin-top: 32px;
  width: 100%;
  height: 48px;
  background: #9c94ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: bolder;
  margin-bottom: 32px;
}

.signUp .form .passwordVerify {
  width: 100%;
  height: 152px;
  background: #2d323e;
  border-radius: 12px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 16px;
}

.signUp .form .passwordVerify li {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
}

.signUp .form .passwordVerify li svg {
  margin-right: 8px;
}

.signUp .line span {
  background: #101a25;
  color: #cccccc;
  font-size: 12px;
  font-weight: 400;
}

.signUp .link {
  width: 100%;
  height: 48px;
  border: 1px solid #9c94ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 0 0 24px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #9c94ff;
  margin-bottom: 32px;
  cursor: pointer;
}

.signUp .link svg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.signUp .haveAccount {
  font-size: 12px;
  font-weight: 400;
  color: #cccccc;
}

.signUp .haveAccount span {
  color: #9c94ff;
  cursor: pointer;
}

/*Google Modal */

.googleModal ion-content {
  --background: #ffffff !important;
}

.googleModal ion-content::part(scroll) {
  padding: 0;
}

.googleModal ion-content::part(scroll)::before {
  display: none;
}

.signGoogle .tit {
  padding: 16px 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 16px;
}

.signGoogle .tit p {
  display: flex;
  align-items: center;
}

.signGoogle .tit p svg {
  margin-right: 16px;
}

.signGoogle .line {
  width: 100%;
  height: 1px;
  background-color: #cccccc;
  margin-bottom: 16px;
}

.signGoogle .des {
  padding: 0 16px;
}

.signGoogle .des .destit {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  letter-spacing: 0.01em;
  margin-bottom: 16px;
}

.signGoogle .des .longDes {
  font-size: 14px;
  font-weight: 400;
  color: #4d4d4d;
  margin-bottom: 16px;
}

.signGoogle .des .longDes span {
  color: #2673e4;
}

.signGoogle .des img {
  margin-bottom: 16px;
}

.signGoogle .des .continue {
  width: 100%;
  height: 46px;
  background: #2673e4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
}

/* forgot password modal */
.forgot {
  color: #ffffff;
  font-family: Open;
}

.forgot .tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
}

.forgot .tit span {
  margin-left: 8px;
}

.forgot .tit svg {
  cursor: pointer;
}

.forgot .line {
  width: 100%;
  height: 1px;
  background-color: #334663;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot .images {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 16px;
}

.forgot .images svg {
  margin-bottom: 32px;
}

.forgot .images > div {
  padding: 0 4px;
  text-align: center;
}

.forgot .inp {
  width: 100%;
  height: 56px;
  border: 2px solid #334663;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 16px;
}

.forgot .inp:focus-within {
  border: 2px solid #9c94ff;
}

.forgot .inp label {
  position: absolute;
  top: -12px;
  left: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #cccccc;
  padding: 0 4px;
  background: #101a25;
}

.forgot .inp input {
  width: 100%;
  outline: none;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
}

.forgot .subBtn {
  width: 100%;
  height: 48px;
  background: #9c94ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
}

/* verify Identity */
.verifyIdentityModal {
  color: #ffffff;
  font-family: Open;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 0 16px;
}

.verifyIdentityModal .tit {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
}

.verifyIdentityModal .tit span {
  margin-left: 8px;
}

.verifyIdentityModal .tit svg {
  cursor: pointer;
}

.verifyIdentityModal .line {
  width: 100%;
  height: 1px;
  background-color: #334663;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verifyIdentityModal .images {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 60px;
}

.verifyIdentityModal .images div {
  margin-top: 40px;
  text-align: center;
}

.verifyIdentityModal .verify {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.verifyIdentityModal .verify li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #334663;
}

.verifyIdentityModal .verify li:last-child {
  margin-top: 16px;
  border: 0;
}

.verifyIdentityModal .verify li > div {
  display: flex;
  align-items: center;
}

.verifyIdentityModal .verify li > div > p {
  display: flex;
  align-items: center;
}

.verifyIdentityModal .verify li > div > p > svg {
  margin-left: 18px;
}

.verifyIdentityModal .verify li > div > svg {
  margin-right: 8px;
}

.verifyIdentityModal .act {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 20px;
}

.verifyIdentityModal .act .iden {
  width: 100%;
  height: 48px;
  background: #9c94ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.verifyIdentityModal .act .later {
  width: 100%;
  height: 48px;
  background: transparent;
  color: #9c94ff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
}

/* authModal  */
.authIonModal {
  padding-bottom: 0 !important;
  z-index: 25555 !important;
}
.authModal {
  font-family: Open;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  padding-bottom: 120px;
}

/*User info */
.authModal .des {
  margin-top: 32px;
  padding: 0 14px;
}

.authModal .des > p {
  font-weight: 600;
  font-size: 30px;
  line-height: 130%;
  color: #9c94ff;
  margin-bottom: 16px;
}

.authModal .des > div {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #48596c;
  margin-bottom: 48px;
}

.authModal .form {
  margin-bottom: 24px;
  padding: 0 14px;
}

.authModal .form .inp {
  width: 100%;
  height: 56px;
  border: 2px solid #334663;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 32px;
}

.authModal .form .inp:focus-within {
  border: 2px solid #9c94ff;
}

.authModal .form .inp label {
  position: absolute;
  top: -12px;
  left: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #cccccc;
  padding: 0 4px;
  background: #101a25;
}

.authModal .form .inp input {
  width: 100%;
  outline: none;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
}

.authModal .form .inp > svg {
  position: absolute;
  right: 16px;
  cursor: pointer;
}

.authModal .sub {
  width: calc(100% - 32px);
  height: 54px;
  background: #9c94ff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 17px;
  color: #000000;
  flex-shrink: 0;
}

/* user pin code  */
.authModal .pinTop {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.authModal .main {
  width: 100%;
}

.authModal .main .pinDes {
  margin-bottom: 60px;
}

.authModal .main .pinDes > div {
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 12px;
}

.authModal .main .pinDes > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #48596c;
}

.authModal .inps {
  width: 100%;
  padding: 0 39px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 64px;
}

.authModal .inps li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  border: 2px solid rgba(156, 148, 255, 0.45);
  border-radius: 8px;
}

.authModal .inps li:focus-within {
  border-color: #9c94ff;
}

/* .authModal .inps li:focus-within div {
    height: 2px;
} */

.authModal .inps li input {
  width: 54px;
  height: 54px;
  outline: none;
  background-color: transparent;
  border: none;
  text-align: center;
  caret-color: transparent;
  font-size: 2em;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 4px;
  color: #9c94ff;
}

/* .authModal .inps li div {
    width: 30%;
    height: 0;
    background: #FFFFFF;
} */

.upLoadImageModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
}

.upLoadImageModal .tit {
  padding-left: 4px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  border-bottom: 1px solid #334663;
  margin-bottom: 16px;
}

.upLoadImageModal .tit svg {
  margin-right: 8px;
}

.upLoadImageModal .des {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #ffffff;
  margin-bottom: 64px;
}

.upLoadImageModal .choose {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 8px;
}

.upLoadImageModal .list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upLoadImageModal .list li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #334663;
}

.upLoadImageModal .list li > div {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}

.upLoadImageModal .list li > div > svg {
  margin-right: 16px;
}

.upLoadImageModal .list li > div > p {
  display: flex;
  align-items: center;
}

.upLoadImageModal .list li > div > p > svg {
  margin-left: 18px;
}

/* driverLicenseModal  */

.driverLicenseModal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 48px;
  padding: 0 16px;
}

.driverLicenseModal .tit {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  border-bottom: 1px solid #334663;
  margin-bottom: 16px;
}

.driverLicenseModal .tit svg {
  margin-right: 8px;
}

.driverLicenseModal .logo {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #ffffff;
  text-align: center;
  margin-top: 32px;
}

.driverLicenseModal .logo p {
  margin-top: 16px;
}

.driverLicenseModal .act {
  width: 100%;
}

.driverLicenseModal .act input {
  display: none;
  top: -100%;
  outline: medium none;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
}

.driverLicenseModal .act label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.driverLicenseModal .act .use {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background: #9c94ff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #000000;
  margin-bottom: 48px;
}

.upLoadResultModal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
}

.upLoadResultModal .des {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.upLoadResultModal .des svg {
  margin-bottom: 24px;
}

.upLoadResultModal .des p {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 16px;
}

.upLoadResultModal .des div {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #ffffff;
}

.upLoadResultModal .sub {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: #9c94ff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #000000;
}
