.mySwiper {
  width: 100%;
  margin-bottom: 20px;
  overflow: visible;
  --swiper-pagination-top: 110%;
  --swiper-pagination-bullet-inactive-color: gray !important;
  -webkit-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
}
.mySwiper .oneticket {
  width: 80% !important;
  display: flex;
  flex-direction: column;
}
.mySwiper .one {
  position: relative;
  width: 100%;
}
.mySwiper .one > img:first-child {
  width: 100%;
  border-radius: 15px 15px 0 0;
}
.mySwiper .one > img:nth-child(2) {
  position: absolute;
  height: auto;
  width: 100%;
  bottom: -6%;
  left: 0;
}
.mySwiper .one .circleline {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  bottom: -16px;
}
.mySwiper .one .circleline > div {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #101a25;
}
.mySwiper .one .circleline .margin {
  width: 32px;
  height: 32px;
}
.mySwiper .one .jackpot {
  width: 100%;
  position: absolute;
  left: 0;
  top: 20px;
  text-align: center;
}
.mySwiper .one .jackpot > span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20.5955px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 32.5424px rgba(0, 0, 0, 0.1);
}
.mySwiper .one .jackpot > p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 76px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 32.5424px rgba(0, 0, 0, 0.1);
}
.mySwiper .bottom {
  width: 100%;
  height: 122px;
  background: #ffffff;
  border-radius: 0 0 15px 15px;
  padding: 0 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.mySwiper .bottom > p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.216949px;
  color: #000000;
  text-shadow: 0px 0px 32.5424px rgba(0, 0, 0, 0.1);
}
.mySwiper .bottom > p:first-child{
  margin-bottom: 5px;
}
.mySwiper .bottom > p > span {
  font-weight: 600;
}
.mySwiper .splitClass {
  width: 100%;
  overflow: hidden;
  padding: 0 5px 5px;
  position: relative;
  border-radius: 20px;
  background: var(--main-diamond, #42e0a7);
}
.mySwiper .splitClass .progress {
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
}
.mySwiper .splitClass .progress p {
  color: #000;
  text-align: center;
  font-family: "Open";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.mySwiper .splitClass::after {
  box-sizing: border-box;
  content: "";
  right: -11px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #101a25;
  position: absolute;
  bottom: 111px;
  z-index: 9;
  border: 5px solid var(--main-diamond, #42e0a7);
}
.mySwiper .splitClass::before {
  box-sizing: border-box;
  content: "";
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #101a25;
  position: absolute;
  bottom: 111px;
  left: -11px;
  z-index: 9;
  border: 5px solid var(--main-diamond, #42e0a7);
}
.mySwiper .ticket_share {
  display: flex;
}
.mySwiper  .ticket_share_txt span {
  font-weight: 600;
}
.mySwiper  .ticket_share_txt{
  color: var(--text-black, #000);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
.mySwiper .ticket_share ul {
  position: relative;
  display: flex;
  margin-right: 10px;
}
.mySwiper .ticket_share ul li {
  margin-right: -3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #fff;
}
.mySwiper .ticket_share ul li:nth-child(0) {
  z-index: 19;
}
.mySwiper .ticket_share ul li:nth-child(1) {
  z-index: 18;
}
.mySwiper .ticket_share ul li:nth-child(2) {
  z-index: 17;
}
.mySwiper .ticket_share ul li img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}
