.share {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 18%;
}
.share > img {
  width: 106.81px;
  height: 105.69px;
  margin-bottom: 16px;
}
.share > p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px;
}
.share > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.share > div p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #9b9fae;
}
