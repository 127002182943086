.upComingTop {
    height: 52px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #CCCCCC;
    padding: 0 16px;
    position: fixed;
    top: 0;
    background: #101A25;
}

.icon {
    display: flex;
    align-items: center;
}

.icon div {
    cursor: pointer;
}

.isRingActive {
    position: relative;
    margin-left: 20px;
}

.isRingActive div {
    position: absolute;
    top: 0;
    right: -6px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #9C94FF;
}

.rigthData {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #FFFFFF;
}

.datas {
    display: flex;
    align-items: center;
}

.datas:nth-child(2) {
    margin-left: 20px;
}

.datas svg {
    margin-right: 8px;
}

.unAuth {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.unAuth ion-button {
    --ion-font-family: Open !important;
    width: 86px;
    height: 32px;
    border-radius: 8px;
    --border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unAuth ion-button:nth-child(1) {
    --color: #9C94FF;
    --background: transparent;
    border: 1px solid #9C94FF;
    margin-right: 8px;
}

.unAuth ion-button:nth-child(2) {
    --background: #9C94FF;
    --color: #000;
}


.signUp {
    color: #FFFFFF;
    font-family: Open;
}

.signUp .tit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
}

.signUp .tit svg {
    cursor: pointer;
}

.signUp .line {
    width: 100%;
    height: 1px;
    background-color: #334663;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signUp .form .inp {
    width: 100%;
    height: 56px;
    border: 2px solid #334663;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 12px;
    margin-bottom: 32px;
}

.signUp .form .inp:focus-within {
    border: 2px solid #9C94FF;
}

.signUp .form .inp label {
    position: absolute;
    top: -12px;
    left: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #CCCCCC;
    padding: 0 4px;
    background: #101A25;
}

.signUp .form .inp input {
    width: 100%;
    outline: none;
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff;
}

.signUp .form .inp>svg {
    position: absolute;
    right: 16px;
    cursor: pointer;
}

.signUp .form .forget {
    margin-top: -16px;
    font-size: 12px;
    font-weight: 400;
    color: #9C94FF;
}

.signUp .form .sub {
    margin-top: 32px;
    width: 100%;
    height: 48px;
    background: #9C94FF;
    border-radius: 8PX;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-weight: bolder;
    margin-bottom: 32px;
}

.signUp .form .passwordVerify {
    width: 100%;
    height: 152px;
    background: #2D323E;
    border-radius: 12px;
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: -20px;
    margin-bottom: 16px;
}

.signUp .form .passwordVerify li {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
}

.signUp .form .passwordVerify li svg {
    margin-right: 8px;
}

.signUp .line span {
    background: #101A25;
    color: #CCCCCC;
    font-size: 12px;
    font-weight: 400;
}

.signUp .link {
    width: 100%;
    height: 48px;
    border: 1px solid #9C94FF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 0 0 24px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #9C94FF;
    margin-bottom: 32px;
    cursor: pointer;
}

.signUp .link svg {
    margin-right: 8px;
}

.signUp .haveAccount {
    font-size: 12px;
    font-weight: 400;
    color: #CCCCCC;
}

.signUp .haveAccount span {
    color: #9C94FF;
    cursor: pointer;
}

/*Google Modal */


.googleModal ion-content {
    --background: #ffffff !important;
}

.googleModal ion-content::part(scroll) {
    padding: 0;
}

.googleModal ion-content::part(scroll)::before {
    display: none;
}

.signGoogle .tit {
    padding: 16px 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 16px;
}

.signGoogle .tit p svg {
    margin-right: 16px;
}

.signGoogle .line {
    width: 100%;
    height: 1px;
    background-color: #CCCCCC;
    margin-bottom: 16px;
}

.signGoogle .des {
    padding: 0 16px;
}

.signGoogle .des .destit {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    letter-spacing: 0.01em;
    margin-bottom: 16px;
}

.signGoogle .des .longDes {
    font-size: 14px;
    font-weight: 400;
    color: #4D4D4D;
    margin-bottom: 16px;
}

.signGoogle .des .longDes span {
    color: #2673E4;
}

.signGoogle .des img {
    margin-bottom: 16px;
}

.signGoogle .des .continue {
    width: 100%;
    height: 46px;
    background: #2673E4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    cursor: pointer;
}

/* forgot password modal */
.forgot {
    color: #FFFFFF;
    font-family: Open;
}

.forgot .tit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
}

.forgot .tit span {
    margin-left: 8px;
}

.forgot .tit svg {
    cursor: pointer;
}

.forgot .line {
    width: 100%;
    height: 1px;
    background-color: #334663;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgot .images {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.forgot .images svg {
    margin-bottom: 32px;
}

.forgot .images>div {
    padding: 0 4px;
    text-align: center;
}

.forgot .inp {
    width: 100%;
    height: 56px;
    border: 2px solid #334663;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 12px;
    margin-bottom: 16px;
}

.forgot .inp:focus-within {
    border: 2px solid #9C94FF;
}

.forgot .inp label {
    position: absolute;
    top: -12px;
    left: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #CCCCCC;
    padding: 0 4px;
    background: #101A25;
}

.forgot .inp input {
    width: 100%;
    outline: none;
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff;
}

.forgot .subBtn {
    width: 100%;
    height: 48px;
    background: #9C94FF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    cursor: pointer;
}

/* email verify modal  */
.emailVerifyModal {
    color: #FFFFFF;
    font-family: Open;
}

.emailVerifyModal .tit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}

.emailVerifyModal .tit span {
    margin-left: 8px;
}

.emailVerifyModal .tit svg {
    cursor: pointer;
}

.emailVerifyModal .line {
    width: 100%;
    height: 1px;
    background-color: #334663;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.emailVerifyModal .images {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 60px;
}

.emailVerifyModal .images div {
    margin-top: 20px;
    text-align: center;
}

.emailVerifyModal .inp {
    width: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}

.emailVerifyModal .inp li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 71px;
    height: 102px;
    border: 2px solid #334663;
    border-radius: 8px;
}

.emailVerifyModal .inp li:focus-within {
    border-color: #9C94FF;
}

.emailVerifyModal .inp li:focus-within div {
    height: 2px;
}

.emailVerifyModal .inp li input {
    width: 54px;
    height: 54px;
    outline: none;
    background-color: transparent;
    border: none;
    text-align: center;
    caret-color: transparent;
    font-size: 40px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 4px;
}

.emailVerifyModal .inp li div {
    width: 30%;
    height: 0;
    background: #FFFFFF;
}

.emailVerifyModal .resend {
    font-style: 16px;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
}

.emailVerifyModal .resend span {
    color: #9C94FF;
}

/* sign in  after  JoinedGames page */
.Auth {
    display: none;
}

.gamefont {
    position: absolute;
    width: 229px;
    height: 88px;
    left: -2px;
    top: 19%;
    background-color: rgba(0, 68, 211, 0.8);
    z-index: 1;
}

.gamefont .fontone {
    position: absolute;
    width: 204px;
    height: 27px;
    left: 23px;
    top: 10px;

    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.gamefont .fonttwo {
    position: absolute;
    width: 204px;
    height: 52px;
    left: 23px;
    top: 30px;
    font-family: 'Open Sans';
    font-size: 38px;
    font-weight: 700;
    line-height: 52px;
    display: flex;
    align-items: center;
    color: #FFFFFF;

}

.gameicon {
    position: absolute;
    left: 2px;
    top: 29%;
    width: 100%;
    height: 15px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 20px;

}

.gameicon .gamepoint {
    position: relative;
    top: -3px;
}

.gameicon .span {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: #FFFFFF;
    text-transform: uppercase;
    letter-spacing: 0.5;
}

.gamemusic {
    width: 50px;
    display: flex;
    justify-content: space-between;

}

/* .gamemusic p{
    margin: 0 0 20px 20px ;
} */

.gamemusic .musicIcon {
    position: relative;
    top: 1.5px;
    left: 2.5px;

}

.join {
    margin-left: 5px !important;
    height: 24px;
    font-family: Open Sans;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

/* PastGame page css */
.winner {
    font-size: 12px;
    /* font-family: 'Open Sans';
    font-weight: 600;
    line-height: 10px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    text-transform: uppercase;
    letter-spacing: 0.5; */
}

.winnerimg {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;
}

.winnerimg>img {
    width: 100%;
    height: 100%;
}

/*HighToLow Page css  */
.HighToLowfont {
    position: absolute;
    width: 229px;
    height: 88px;
    left: -2px;
    top: 109px;
    /* background-color: rgba(0,68,211,0.8); */
    z-index: 1;
}

.HighToLowfont p {
    position: relative;
    width: 200px;
    height: 27px;
    left: 30px;
    top: 3px;
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.HighToLowfont p:nth-child(3) {
    margin-top: 6px;
}

/* Winning pages  css*/
.bankBox {
    width: 380px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #9C94FF;
    border-radius: 10px;
    margin-left: 15px
}

.bankBox p {
    color: #9C94FF;
}

.delBtn {
    position: absolute;
    width: 56px;
    height: 48px;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5%;
    padding-top: 2%;
    z-index: 9999;
}

.delBtn>svg {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
}