.notification_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.notification_container .notification_text {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.notification_text span:first-child {
  height: 24px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.notification_text span:last-child {
  height: 24px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #9c94ff;
}

.notification_container .notification {
  flex: 1;
  /* width: 100%;
    height: 100%; */
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  /* padding-bottom: 120px; */
  overflow-y: scroll;
}
.notification_read {
  height: 48px;
  display: flex;
  align-items: center;
  flex: 0 0 8px;
  margin: 0 16px;
}
.notification_read p {
  width: 100%;
  height: 8px;
  background: #9c94ff;
  border-radius: 50%;
}

.notification_right > p {
  font-family: Open;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #ffffff;
  flex-grow: 1;
  margin-bottom: 8px;
}
.notification_content {
  width: 100%;
  display: flex;
}
.notification_avatar {
  margin-right: 16px;
  flex: 0 0 48px;
  height: 48px;
}
.notification_avatar {
}
.notification_button_group {
  display: flex;
  margin-bottom: 8px;
}

.notification_container .notification_left_btn {
  padding: 0px;
  width: 80px;
  height: 32px;
  line-height: 32px;
  background-color: #9c94ff;
  color: #000000;
  border-radius: 8px;
  order: 0;
}
.notification_container .notification_button_group button:disabled {
  filter: grayscale(100%) brightness(80%);
}
.notification_container .notification_right_btn {
  padding: 0px;
  width: 80px;
  height: 32px;
  border: 1px solid #9c94ff;
  border-radius: 8px;
  order: 1;
  margin-left: 10px;
  color: #9c94ff;
  background-color: #101a25;
}

.notification_bottom {
  height: 16px;
  line-height: 16px;
}

.notification_bottom span {
  height: 16px;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 12px;
  color: #a6a9b8;
}

.notification_line {
  width: 100%;
  height: 0px;
  border: 1px solid #334663;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 12px 0;
}
.no_more {
  color: #fff;
  text-align: center;
}

/* BuySplitTicket */
.split_wrapper {
}
.split {
  height: 100%;
  padding: 24px;
  background: var(--background-background-light, #2d323e);
}
.split_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.split_header h5 {
  color: var(--text-primary, #fff);
  margin: 0;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.split_header span {
  color: var(--main-main, #9c94ff);
  text-align: right;

  /* Btn/MediumBtn */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.2px;
}
.split_card {
}
.split_card {
  display: flex;
  align-items: center;
}
.split_card .card_image {
  width: 100px;
  height: 75px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 13px;
}
.split_card .card_image img {
  width: 100%;
  object-fit: over;
  object-position: center top;
}
.card_info {
  flex: 1;
}
.card_info span {
  margin: 0;
  color: var(--text-primary, #fff);
  font-family: Open Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 160% */
}
.card_info p {
  margin: 0;
  height: 3px;
  width: 100%;
}
.card_info h5 {
  margin: 0;
  color: var(--text-primary, #fff);
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}
.progress_line {
  height: 5px;
  /* Special/grad */
  background: linear-gradient(270.17deg, #7190ff 0.15%, #ac8fff 99.88%);
  border-radius: 12px;
  transition: all 2s linear;
  animation: progressWidth 2s linear;
}

.joined {
  height: 5px;
  transition: all 2s linear;
  animation: progressWidth 2s linear;

  /* Special/grad_green */

  background: linear-gradient(270.17deg, #5ce46a 0.15%, #2ea51b 99.88%);
  border-radius: 12px;
}

.progrebox {
  width: 100%;
  height: 5px;
  margin: 4px 0;
  background: #101a25;
  border-radius: 12px;
}
.card_share ul {
  position: relative;
  display: flex;
  margin-right: 10px;
}
.card_share {
  display: flex;
}
.card_share ul li {
  margin-right: -3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #fff;
}
.card_share ul li:nth-child(0) {
  z-index: 19;
}
.card_share ul li:nth-child(1) {
  z-index: 18;
}
.card_share ul li:nth-child(2) {
  z-index: 17;
}
.card_share ul li img {
  width: 100%;
  height: 100%;
}
.card_share span {
  color: var(--text-seconday, #ccc);
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

/* prize */
.split_prize {
  margin: 16px 0;
  position: relative;
  width: 100%;
  height: 48px;
  border: 1px solid #334663;
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.split_prize .beting {
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
.split_prize .fontsize1 {
  font-size: 12px;
}
.split_prize .winning {
  color: #42e05c;
}
.split_prize .fontsize2 {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.split_prize .svg {
  margin: 0 8px;
}
.split_prize .svg svg {
}
.buy_split_ticket {
  --backdrop-opacity: 0;
  --width: calc(100% - 18px);
  border-radius: 8px 8px 0 0;
  --background: rgba(0, 0, 0, 0.5);
  /* backdrop-filter: blur(2px); */
  z-index: 25557 !important;
}

.share {
  width: 100%;
}
.share_header {
  padding: 0 16px;
  height: 48px;
  display: flex;
  align-items: center;
  background-color: #101a25;
}
.share_wrapper {
  position: relative;
  width: 100%;
}
.share_wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 15px 15px 0 0;
}
.circle_line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: -16px;
}
.circle_line > div {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #101a25;
}
.circle_line > div:first-child {
  margin-left: -16px;
  width: 32px;
  height: 32px;
}
.circle_line > div:last-child {
  width: 32px;
  height: 32px;
  margin-right: -16px;
}
.jackpot {
  width: 100%;
  position: absolute;
  left: 0;
  top: 10%;
  z-index: 9;
  text-align: center;
}
.jackpot > span {
  color: var(--text-primary, #fff);
  text-align: center;
  text-shadow: 0px 0px 32.54237365722656px 0px rgba(0, 0, 0, 0.1);
  font-family: Open Sans;
  font-size: 20.595px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
}
.jackpot > p {
  color: var(--text-primary, #fff);
  text-align: center;
  text-shadow: 0px 0px 32.54237365722656px 0px rgba(0, 0, 0, 0.1);
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 76px;
}
.share .ticket_bottom {
  width: 100%;
  height: 122px;
  background: #ffffff;
  border-radius: 0 0 15px 15px;
  padding-left: 22px;
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.share .ticket_bottom > .ticket_number {
  color: var(--text-black, #000);
  text-shadow: 0px 0px 32.54237365722656px 0px rgba(0, 0, 0, 0.1);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 26.034px; /* 185.956% */
}
.share .ticket_bottom > .ticket_number span {
  font-weight: 600;
}
.share_background {
  overflow: hidden;
  position: relative;
  padding: 0 5px 5px;
  border-radius: 20px;
  background: var(--main-diamond, #42e0a7);
}
.share_background::before {
  box-sizing: border-box;
  content: '';
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #101a25;
  position: absolute;
  bottom: 111px;
  left: -11px;
  z-index: 9;
  border: 5px solid var(--main-diamond, #42e0a7);
}
.share_background::after {
  box-sizing: border-box;
  content: '';
  right: -11px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #101a25;
  position: absolute;
  bottom: 111px;
  z-index: 9;
  border: 5px solid var(--main-diamond, #42e0a7);
}
.share .progress {
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
}
.share .progress p {
  text-align: center;
  color: var(--text-black, #000);
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
}
.ticket_share {
  display: flex;
}
.ticket_share ul {
  position: relative;
  display: flex;
  margin-right: 10px;
}
.ticket_share ul li {
  margin-right: -3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #fff;
}
.ticket_share ul li:nth-child(0) {
  z-index: 19;
}
.ticket_share ul li:nth-child(1) {
  z-index: 18;
}
.ticket_share ul li:nth-child(2) {
  z-index: 17;
}
.ticket_share ul li img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}
.ticket_share .ticket_share_txt span {
  font-weight: 600;
}
.ticket_share .ticket_share_txt {
  color: var(--text-black, #000);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
.share_bottom {
  margin-top: 20px;
  z-index: 999;
  bottom: 40px;
  width: 100%;
}
.share_container {
  flex-direction: column;
  display: flex;
  padding: 10px 18px;
  position: relative;
}
.share_success {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.share_success svg {
  margin-bottom: 16px;
}
.buy_ticket_success {
  z-index: 25560 !important;
  padding-bottom: 0 !important;
}