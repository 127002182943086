@font-face {
    font-family: Open Sans;
    src: url('../style//font/Open-Sans-2.ttf');
}

html body {
    width: 100%;
    height: 100%;
}

.account {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #cccccc;
    padding: 16px 16px 120px;
    font-family: Open !important;
    position: relative;
}

.account .topDes {
    position: relative;
    width: 100%;
    margin-bottom: 16px;
}

.account .topDes>span {
    position: absolute;
    top: 0;
    right: 0;
}

.account .topDes .avatarAnd {
    padding-left: 22px;
    padding-top: 20px;
    display: flex;
    align-items: center;
}

.account .topDes .avatarAnd img {
    width: 82px;
    height: 82px;
    margin-right: 22px;
    border-radius: 50%;
    flex-shrink: 0;
}

.account .topDes .avatarAnd svg {
    width: 82px;
    height: 82px;
    margin-right: 22px;
    border-radius: 50%;
}

.account .topDes .avatarAnd>div {
    align-self: start;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.account .topDes .avatarAnd>div span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.account .topDes .avatarAnd>div p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #CCCCCC;
}

.account .topDes .avatarAnd>div p:last-child {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    margin-top: 2px;
    margin-left: -42px;
}

.moneyAndAct .money {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.money div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.money p {
    display: flex;
    align-items: center;
    justify-content: center;
}

.account .topDes .moneyAndAct {
    margin-top: -16px;
    width: 100%;
    padding: 32px 8px;
    background: #2D323E;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.account .topDes .moneyAndAct .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: flex-end;
    margin-top: 20px;
}

.account .topDes .moneyAndAct .money span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #9C94FF;
}

.account .topDes .moneyAndAct .money p {
    height: 24px;
    font-family: Open Sans;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #FFFFFF;
    margin-left: 5px;
}

.account .topDes .moneyAndAct .top .act {
    display: flex;
    align-items: center;
}

.account .topDes .moneyAndAct .top .act .drawn {
    width: 86px;
    height: 32px;
    border: 1px solid #9C94FF;
    border-radius: 8px;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9C94FF;
    margin-right: 8px;
}

.account .topDes .moneyAndAct .top .act .depo {
    width: 86px;
    height: 32px;
    background: #9C94FF;
    border-radius: 8px;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
}

.account .actions {
    width: 100%;
}

.account .actions li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #334663;
    height: 56px;
}

.account .actions li:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border: 0;
}

.account .actions li>div {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.account .actions li>div p {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #9C94FF;
    margin-left: 12px;
}

.account .actions li>div p>svg {
    margin-left: 4px;
}

.account .actions li>div>svg {
    margin-right: 12px;
    width: 24px;
    height: 24px;
}

/* depositModal */
ion-modal.depositModal {
    --ion-font-family: Open;
    --color: #FFFFFF;
}

.depositModal {
    width: 100%;
    font-family: Open;
    color: #FFFFFF;
    padding-bottom: 20px;
}

.depositModal .tit {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: space-between;
    border-bottom: 1px solid #334663;
    margin-bottom: 16px;
}

.depositModal .tit>span {
    flex: 8;
    padding-left: 16px;
}

.depositModal .tit>div {
    flex: 1;
    height: 48px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.depositModal .formm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
}

.depositModal .formm .amount {
    width: 100%;
    height: 56px;
    border: 2px solid #334663;
    border-radius: 8px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 16px;
    margin-bottom: 32px;
}

.depositModal .formm .amount:focus-within {
    border-color: #9C94FF;
}

.depositModal .formm .amount:focus-within span {
    color: #9C94FF;
}

.depositModal .formm .amount input {
    width: 100%;
    height: 100%;
    outline: none;
    border: 0;
    background: transparent;
    text-align: right;
}

.depositModal .formm .amount span {
    position: absolute;
    top: -12px;
    left: 16px;
    background: #101A25;
    padding: 0 4px;
    color: #CCCCCC;
    font-size: 14px;
}

.depositModal .formm .amount div {
    position: absolute;
    left: 16px;
    font-size: 16px;
    color: #CCCCCC;
}

.depositModal .formm .inputleft {
    margin-bottom: 16px !important;
}

.depositModal .formm .inputleft input {
    text-align: left !important;
}

.depositModal .formm .checkBox {
    width: 100%;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 36px;
}

.depositModal .formm .checkBox .onePay {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
}

.depositModal .formm .checkBox .onePay:last-child {
    margin-bottom: 0;
}

.depositModal .formm .checkBox .onePay .leftSvg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.depositModal .formm .checkBox .onePay>svg {
    margin-left: 12px;
}

.depositModal .formm .dateAndCode {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}

.depositModal .formm .dateAndCode .one {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.depositModal .formm .dateAndCode .one:last-child {
    margin-bottom: 0;
}

.depositModal .formm .dateAndCode .one>span {
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
}

.depositModal .formm .dateAndCode .one>div {
    display: flex;
    align-items: center;
}

.depositModal .formm .dateAndCode .one>div span {
    margin: 0 8px;
}

.depositModal .formm .dateAndCode .one>div input {
    width: 82px;
    height: 56px;
    padding: 0 12px;
    border: 2px solid #334663;
    border-radius: 8px;
    background: transparent;
    outline: none;
}

.depositModal .formm .dateAndCode .one>div input:focus-visible {
    border-color: #9C94FF;
}

.depositModal .formm .dateAndCode .one .code input {
    width: 184px;
}

.depositModal .btn {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #9C94FF;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    margin-bottom: 16px;
}

.depositModal .bottomDes {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #CCCCCC;
}

.depositModal .bottomDes span {
    color: #9C94FF;
    font-weight: 600;
}


.copyKeyModal ion-content {
    --padding-start: 0 !important;
    --padding-end: 0 !important;
    --padding-top: 0 !important;
    --padding-bottom: 0 !important;
    --color: #FFFFFF;
}

.account .copyKeyModal {
    height: 100vh;
}

.copyKeyModalMain {
    width: 100%;
    font-family: Open;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 54px;
}

.copyKeyModalMain .top {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-weight: 400;
    font-size: 18px;
}

.copyKeyModalMain .top>span {
    flex: 7;
    text-align: center;
}

.copyKeyModalMain .top>div {
    flex: 2;
    height: 48px;
    display: flex;
    align-items: center;
}

.copyKeyModalMain .top>div:first-child {
    padding-left: 16px;
}

.copyKeyModalMain .top .save {
    flex-shrink: 0;
    width: 55px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #9C94FF;
    border-radius: 19px;
    font-weight: 400;
    font-size: 14px;
    color: #101A25;
}

.copyKeyModalMain .main {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.copyKeyModalMain .main .avatar {
    margin-top: 36px;
    position: relative;
    margin-bottom: 24px;
}

.copyKeyModalMain .main .avatar img {
    width: 96px;
    height: 96px;
    border: 2px solid rgba(255, 255, 255, 0.6);
    border-radius: 60px;
}

.copyKeyModalMain .main .avatar svg {
    position: absolute;
    bottom: 0;
    right: 0;
}

.copyKeyModalMain .main .name {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #FFFFFF;
    margin-bottom: 48px;
}

.copyKeyModalMain .main .email {
    width: calc(100% - 32px);
    margin-bottom: 24px;
    position: relative;
}

.copyKeyModalMain .main .nameExist input {
    border: 1px solid red !important;
    color: red !important;
}

.copyKeyModalMain .main .nameExist p {
    color: red !important;
}

.copyKeyModalMain .main .email p {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #A8ABB9;
    margin-bottom: 4px;
}

.copyKeyModalMain .main .email input {
    height: 68px;
    background: transparent;
    outline: none;
    border: none;
    width: 100%;
    padding-left: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    border: 1px solid #334663;
    border-radius: 12px;
}

.copyKeyModalMain .main .realName {
    width: calc(100% - 32px);
    height: 68px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
    border: 1px solid #334663;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;
    margin-bottom: 24px;
}

.copyKeyModalMain .main .privatekey {
    width: 100%;
    padding: 0 16px;
}

.copyKeyModalMain .main .privatekey>p {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #A8ABB9;
    margin-bottom: 12px;
}

.copyKeyModalMain .main .privatekey .btn {
    width: 100%;
    height: 54px;
    background: #9C94FF;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 17px;
    color: #000000;
}

.copyKeyModalMain .main .privatekey .btn svg {
    margin-right: 12px;
}


.copyKeyModalMain .main .hasKey {
    width: 100%;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(156, 148, 255, 0.06);
    border: 1px solid #334663;
    border-radius: 14px;
    padding: 0 16px;
    font-size: 18px;
}

.copyKeyModalMain .main .hasKey .left {
    display: flex;
    align-items: center;
}

.copyKeyModalMain .main .hasKey .left>div {
    margin-right: 12px;
}



.molbox .balan {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.balantop {
    width: 100%;
    /* background: #000000; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid #334663; */
    background: #000000;
}

.balantop>div {
    flex: 1;
    height: 48px;
}

.balantop>div:first-child {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 16px;
}

.balantop .balantopIcon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 16px;
}

.balantop span {
    flex: 7;
    height: 48px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    padding: 12px 0;
}


.balan .showMoney {
    width: calc(100% - 32px);
    /* height: 68px; */
    background: #2D323E;
    border-radius: 8px;
    margin: 16px auto 0;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.balan .showMoney .showdes {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #9C94FF;
    flex-shrink: 0;
    margin-right: 8px;
}

.balan .showMoney .money,
.balan .showMoney .money>div {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
}

.balan .showMoney .money .diamond {
    margin-right: 8px;
}

.balan .showMoney .money>div>svg {
    margin-right: 8px;
}

.balan .changeHistory {
    display: flex;
    align-items: center;
    padding: 0 16px
}

.balan .changeHistory>div {
    width: 73px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #2D323E;
    color: #FFF;
    font-family: Open;
    font-size: 13px;
    font-weight: 400;
    margin-right: 12px;
}

.balan .changeHistory>div:last-child {
    margin-right: 0;
}

.balan .changeHistory .acts {
    background: #9C94FF;
    color: #2D323E;
}

.balanlist {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 0 16px;
    overflow-y: scroll;
}
.balanlist li {
    margin-bottom: 20px;
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid #334663;
    justify-content: space-between;
    align-items: center;
}

.path {
    display: flex;
    align-items: center;
}

.path svg {
    margin-right: 8px;
}

.molbox {
    --min-width: 100%;
    --min-height: 100%;
}

.withdrawn {
    --min-width: 100%;
    --min-height: 100%;
}

.text p {
    font-family: Open;
    font-style: normal;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    text-transform: capitalize;
}

.text span {
    font-family: Open;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #CCCCCC;
    white-space: nowrap;
}

.ching {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    font-family: Open;
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    color: #42E0A7;
}

.ching,
.red {
    padding-right: 16px;
}

.ching>.amount,
.red>.amount {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.red {
    display: flex;
    align-items: center;
    font-family: Open;
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    color: #FFFFFF;
}

.AddKeyModal {
    width: 100%;
}

.AddKeyModal .main {
    padding-top: 64px;
}

.AddKeyModal .main .inp {
    width: 100%;
    height: 56px;
    border: 2px solid #334663;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 12px;
    margin-bottom: 32px;
}

.AddKeyModal .main .inp:focus-within {
    border: 2px solid #9C94FF;
}

.AddKeyModal .main .inp label {
    position: absolute;
    top: -12px;
    left: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #CCCCCC;
    padding: 0 4px;
    background: #101A25;
}

.AddKeyModal .main .inp input {
    width: 100%;
    outline: none;
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff;
}

.AddKeyModal .main .inp>svg {
    position: absolute;
    right: 16px;
    cursor: pointer;
}

.AddKeyModal .main .sub {
    width: 100%;
    height: 54px;
    background: #9C94FF;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 17px;
    color: #000000;
    flex-shrink: 0;
}

.withdrawn .withbox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.withtop {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #334663;
    height: 48px;
}

.withtop>div {
    height: 48px;
    flex: 1;
    display: flex;
    align-items: center;
}

.withtop>div:first-child {
    padding-left: 16px;
}

.withtop .managesvg {
    justify-content: flex-end;
    padding-right: 16px;
}

.withtop p {
    flex: 7;
    height: 24px;
    font-family: Open Sans;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    margin-left: 10px;
}

.withmin {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 4px;
    width: calc(100% - 32px);
    height: 68px;
    background: #2D323E;
    border-radius: 8px;
    margin: 16px auto 0;
    flex: none;
    order: 0;
    flex-grow: 0;
    justify-content: space-between;
}

.withmoney {
    display: flex;
    align-items: center;
}

.withmoney svg {
    margin-right: 4px;
}

.withmoney .withdiamond {
    display: flex;
    align-items: center;
    font-family: Open Sans;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #FFFFFF;
}

.withmoney .withamount {
    display: flex;
    align-items: center;
    margin-left: 8px;
    font-family: Open Sans;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #FFFFFF;
}

.mybalance {
    width: 141px;
    height: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #9C94FF;
}

.withsvg {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding: 0 16px;
}

.withp {
    width: 94%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 24px;
}

.withp p {
    width: 100%;
    padding: 0px 10px 0px 10px;
}

.withbtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 32px 0 0;
    padding: 0 16px;
}

.withbtn p {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #9C94FF;
    border-radius: 8px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.addbankmodal {
    --min-width: 100%;
    --min-height: 100%;
}

.addbankmodal .addbankbox {
    width: 100%;
    /* padding-bottom: 80px; */
    padding-bottom: 20px;

}

.addbankul {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
}

.addbankul li {
    position: relative;
}

.holder input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 12px;
    width: 100%;
    height: 56px;
    outline: none;
    background: transparent;
    border: 1px solid #334663;
    border-radius: 8px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 24px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.holder span {
    position: absolute;
    top: 19px;
    left: 10px;
    height: 12px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #CCCCCC;
    flex: none;
    order: 0;
    flex-grow: 0;
    background: #101A25;
    z-index: 99;
}

.holder p {
    width: 90%;
    height: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #CCCCCC;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    padding-left: 15px;
    margin-top: 5px;
}

.holder .select {
    display: flex;
    padding: 0px 20px 0 8px;
    width: 100%;
    height: 56px;
    outline: none;
    background: transparent;
    border: 1px solid #334663;
    border-radius: 8px;
    color: #FFFFFF;
    margin-top: 24px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.holder select option {
    background: #101A25;
    height: 24px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.addbanksvg {
    position: absolute;
    left: 85%;
    top: 50%;
}

.withinp {
    position: relative;
    margin-top: 24px;
    width: calc(100% - 32px);
    margin: 0 auto;
}

.withinp input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 12px;
    width: 100%;
    height: 56px;
    outline: none;
    background: transparent;
    border: 1px solid #334663;
    border-radius: 8px;
    color: #FFFFFF;
    margin-top: 24px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    padding-left: 35px;
}

.withinp span {
    height: 12px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #CCCCCC;
    background: #101A25;
    position: absolute;
    top: 19px;
    left: 14px;
}

.withinp p {
    position: absolute;
    top: 42px;
    left: 13px;
}

.withselect {
    width: calc(100% - 32px);
    margin: 0 auto;
    position: relative;
    display: flex;
    outline: none;
    background: transparent;
    border: 1px solid #334663;
    border-radius: 8px;
    color: #FFFFFF;
    margin-top: 24px;
    height: 78px;
    flex-direction: column;
    padding-left: 5px;
}

.withselect .select {
    display: flex;
    padding: 0px 20px 0 8px;
    width: 100%;
    height: 56px;
    background: transparent;
    color: #d1d1d1;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;


}

.withselect select option {
    background: #101A25;
    height: 24px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #e0d9d9;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.withselect p {
    margin-top: 17px;
    margin-left: 7px;
    height: 24px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #ffffff;
}

.withselect span {
    height: 12px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #CCCCCC;
    background: #101A25;
    position: absolute;
    top: -6px;
    left: 14px;
}

.withselect div {
    position: absolute;
    top: 35%;
    left: 92%;
}

.withtext {
    width: 100%;
    height: 64px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #CCCCCC;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 16px;
    padding: 0 16px;
}

.withdramodal {
    --min-width: 100%;
    --min-height: 100%;
}

.withdrabox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
}

.withdrabox .withdrasvg {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.withdrasvg p {
    height: 24px;
    font-family: Open Sans;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 24px;
}

.withdrasvg .withdrap {
    height: 48px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #c7c7c7;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 16px;
}

.withdrabtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 48px;
    background: #9C94FF;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 70px;
}

.withdrabtn p {
    height: 16px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #000000;
}

.openHelpModal {
    padding-bottom: 20px;
}

.openHelpModal .tit {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.openHelpModal .tit>p {
    flex: 7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.openHelpModal .tit>div {
    flex: 1;
    height: 48px;
    display: flex;
    align-items: center;
}

.openHelpModal .tit>div:first-child {
    padding-left: 7px;
    padding-right: 9px;
}

.openHelpModal .videoList {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 0 16px;
}

.openHelpModal .videoList li {
    width: 100%;
    height: auto;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.openHelpModal .videoList li>div:first-child>div>button,
.openHelpModal .videoList li>div:nth-child(2)>div>button {
    display: none;
}

.openHelpModal .videoList li:last-child {
    margin-bottom: 0;
}

.openHelpModal .videoList li video,
.openHelpModal .videoList li img {
    width: 100%;
    border-radius: 8px;
    margin: 0;
}

.openHelpModal .videoList li .videostart {
    position: absolute;
    left: calc(50% - 28px);
    top: calc(50% - 32px);
    z-index: 99999;
}

.openHelpModal .videoList li .videoDes {
    /* position: absolute;
    bottom: 6px;
    left: 0; */
    width: 100%;
    height: 52px;
    margin-top: -52px;
    background: rgba(45, 50, 62, 0.9);
    border-radius: 0 0 8px 8px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    font-weight: 400;
    font-size: 15px;
    color: #FFFFFF;
    z-index: 99999;
}

.openHelpModal .videoList li .videoTime {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 9px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: rgba(45, 50, 62, 0.9);
    border-radius: 6px;
    font-size: 12px;
    color: #FFFFFF;
    z-index: 999;
}

.myticketsbox {
    display: flex;
    flex-direction: column;
    --min-width: 100%;
    --min-height: 100%;
}

.myticketsbox .mytickets_top {
    height: 48px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background: #000000;
}

.mytickets_top>div {
    flex: 1;
    height: 48px;
    display: flex;
    align-items: center;
}

.mytickets_top>div:first-child {
    padding-left: 16px;
}

.mytickets_top span {
    flex: 7;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Open;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.mytickets_card {
    margin-top: 41px;
    width: 100%;
    padding-bottom: 50px;
}

.mytickets_card .mySwiper {
    width: 100%;
    --swiper-pagination-bottom: -10%;
    --swiper-pagination-bullet-inactive-color: gray;
    overflow: visible;
}

.mytickets_card_li {
    width: 100%;
}

.mytickets_one {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.mytickets_one_box {
    position: relative;
    width: 95%;
    height: 424.01px;
    border-radius: 18px;
    /* background: #AE92A6; */
    display: flex;
    flex-direction: column;
    overflow: hidden;

}

.mytickets_left {
    position: absolute;
    width: 15px;
    height: 424.01px;
    left: 0%;
    top: 0;
    background: #272a32;
    border-radius: 0 18px 18px 0;
}

.mytickets_rigth {
    position: absolute;
    width: 15px;
    height: 424.01px;
    right: 0%;
    top: 0;
    background: #5a5d65;
    border-radius: 18px 0 0 18px;
}

.mytickets_rigth_round {
    position: absolute;
    width: 22px;
    height: 22px;
    right: 60%;
    top: 123px;
    border-radius: 50%;
    background: #101A25;
}

.mytickets_rigth_bottom {
    position: absolute;
    width: 15px;
    height: 185px;
    right: 0%;
    top: 441px;
    background: #5a5d65;
    border-radius: 18px 0 0 18px;
}

.mytickets_left_none {
    display: none;
}

.mytickets_left_bottom {
    position: absolute;
    width: 15px;
    height: 185px;
    left: 0%;
    top: 441px;
    background: #272a32;
    border-radius: 0 18px 18px 0;
}

.mytickets_left_round {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 60%;
    top: 123px;
    border-radius: 50%;
    background: #101A25;
}

.mytickets_one_box .mytickets_display_line {
    width: 100%;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-top: 22px solid #AE92A6;
    border-bottom: 22px solid #AE92A6; */
    /* background: #AE92A6; */
    position: relative;
}

.mytickets_one_box .mytickets_display_line>img {
    width: 100%;
    height: 22px;
}

/* .mytickets_round_one {
    width: 22px;
    height: 22px;
    margin-left: -11px;
    border-radius: 50%;
    background: #101A25;
}

.mytickets_round_two {
    width: 22px;
    height: 22px;
    margin-right: -11px;
    border-radius: 50%;
    background: #101A25;
} */

.mytickets_top_line {
    position: absolute;
    /* width: 85%; */
    top: 10.5px;
    height: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    /* left: 7%;
    top: 31%; */
    border: 1px dashed #525252;
}

.mytickets_top_text {
    display: flex;
    justify-content: space-around;
    padding-top: 45px;
    align-items: center;
    background: #AE92A6;
    padding-bottom: 22px;
}

.mytickets_top_text li {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mytickets_top_text li:last-child {
    align-items: flex-end;
}

.mytickets_top_text li p {
    font-family: Open;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #101A25;
    margin-bottom: 5px;
}

.mytickets_top_text li span {
    font-family: Open;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
}

/* .mytickets_top_num {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 5px;
} */

/* .mytickets_top_num span {
    flex: 1;
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
} */

/* .mytickets_top_num p {
    flex: 1;
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
} */



.mytickets_top_back {
    width: 100%;
    height: 294px;
    padding-top: 43px;
    display: flex;
    justify-content: center;
    position: relative;
    background: #AE92A6;
    padding-bottom: 24px;
}

.mytickets_top_back img {
    width: 90%;
    border-radius: 25px;
    object-fit: cover;
    object-position: top;
}

.mytickets_one_bottom {
    width: 95%;
    height: 185px;
    background: #AE92A6;
    border-radius: 18px;
    margin-top: 17px;
}

.mytickets_bottom_textone {
    width: 100%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #2D323E;
    display: flex;
    justify-content: center;
    margin-top: 35px;
}

.mytickets_bottom_texttwo {
    width: 100%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    color: #101A25;
    display: flex;
    justify-content: center;
}

.mytickets_bottom_text {
    width: 100%;
    height: 76.01px;
    background: #AC8AA2;
    display: flex;
    margin-top: 16px;
    border-radius: 0 0 18px 18px;
    justify-content: space-around;
}

.mytickets_bottom_textbox {
    display: flex;
    align-items: center;
}

.mytickets_bottom_textbox span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #2D323E;
    margin-right: 5px;
}

.mytickets_bottom_textbox p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: right;
    color: #101A25;
}

.mytickets_jackpot {
    position: absolute;
    top: 22%;
    left: 40%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 18.9865px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.mytickets_prize {
    position: absolute;
    width: 312px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 76px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 2px 24px rgba(0, 0, 0, 0.5);
}

.mytickets_prize_ {
    position: absolute;
    width: 312px;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 76px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 2px 24px rgba(0, 0, 0, 0.5);
}

.mytickets_none {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 85%;
}

.mytickets_none .mytickets_none_img {
    width: 155px;
    height: 215px;
}

.mytickets_none p {
    font-family: Open;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #9B9FAE;
    margin-top: 30px;
}

/* Draw Record modal */
.gamerecordbox {
    --min-width: 100%;
    --min-height: 100%;
}

.gamerecord_main {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.gamerecordbox .gamerecordbox_top {
    flex-shrink: 0;
    width: 100%;
    display: flex;
    background-color: #000000;
    height: 48px;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
}


.gamerecordbox_top>div {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
}

.gamerecordbox_top>div:first-child {
    padding-left: 16px;
}

.gamerecordbox_top span {
    flex: 7;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.gamerecordbox .gamerecord_tab {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 48px;
    left: 0;
    width: 100%;
    z-index: 99;
    background: #101A25;
}

.gamerecordbox .gamerecord_tab .gamerecord_tab_p {
    width: 100%;
    display: flex;
    align-items: center;
    height: 52px;
    justify-content: space-around;
    border-bottom: 1px solid #334663;
    ;
}

.gamerecord_tab p {
    height: 52px;
    font-family: Open Sans;
    font-style: normal;
    font-size: 14px;
    line-height: 52px;
    text-align: center;
    letter-spacing: 0.002em;
    color: #A6A9B8;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.activation {
    color: #9C94FF !important;
    border-bottom: 2px solid #9C94FF;
}

.gamerecord_ul {
    width: 100%;
    padding: 20px 16px 0 16px;
    overflow-y: scroll;
    flex-direction: column;
}

.gamerecord_ul li {
    display: flex;
    height: 75px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
}

.gamerecord_ul_box {
    display: flex;
}

.gamerecord_ul_box .gamerecord_ul_img {
    display: flex;
    align-items: center;
    width: 100px;
    height: 75px;
    flex: none;
    order: 0;
    flex-grow: 0;
    position: relative;
}

.gamerecord_ul_box .gamerecord_ul_img>img {
    width: 100px;
    height: 75px;
    object-fit: cover;
    object-position: top;
    border-radius: 8px;
}

.gamerecord_ul_box .gamerecord_ul_text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 12px;
}

.gamerecord_ul_text p:nth-child(1) {
    font-family: Open Sans;
    font-style: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.002em;
    color: #FFFFFF;
}

.gamerecord_ul_text p:nth-child(2) {
    font-family: Open Sans;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.002em;
    color: #FFFFFF;
}

.gamerecord_ul_text p:nth-child(3) {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #A6A9B8;
}

.gamerecord_ul .gamerecord_winn span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.002em;
    color: #9C94FF;
}

.gamerecord_ul .gamerecord_defeat {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
    border: 1px solid #F57C38;
    border-radius: 22px;
}

.gamerecord_ul .gamerecord_defeat p {
    height: 16px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.002em;
    color: #F57C38;
}

.gamerecord_ul .gamerecord_more {
    box-sizing: border-box;
    display: flex;
    padding: 3px 0px;
    width: 61px;
    height: 30px;
    border: 1px solid #9C94FF;
    border-radius: 22px;
    align-items: center;
    justify-content: space-evenly;
}

.gamerecord_ul .gamerecord_more p {
    width: 31px;
    height: 16px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.002em;
    color: #9C94FF;
}

.gamerecord_ul_img_mod {
    position: absolute;
    width: 100px;
    height: 75px;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
}

.gamerecord_ul_img_mod p {
    width: 100px;
    height: 75px;
    font-family: Open Sans;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.002em;
    color: #7AF178;
    line-height: 75px;
}

.gamerecord_tab_noneimg {
    display: flex;
    flex-direction: column;
    height: 80%;
    align-items: center;
    justify-content: center;
}

.gamerecord_tab_noneimg p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #9B9FAE;
    margin-top: 30px;
}


.account .emailShare {
    position: fixed;
    right: 14px;
    bottom: 26%;
    width: 78px;
    transition: all .3s;
}

.account .emailShare>div {
    position: relative;
}

.account .emailShare>div div {
    width: 78px;
    height: auto;
}

.account .emailShare>div div .emails {
    width: 78px;
}

.account .emailShare>div .closeIcon {
    position: absolute;
    right: -8px;
    top: -16px;
    width: 16px;
    height: 16px;
}

.account .hideShare {
    right: -36px;
    transition: all .3s;
}

/* email share  */
.emailInputModal {
    width: 100%;
    height: 100%;
    background: #2D323E;
    padding: 40px 16px 0;
    display: flex;
    flex-direction: column;
    /* align-items: center; */

}

.emailInputModal>p {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #FFFFFF;
    margin-bottom: 22px;
}

.emailInputModal .inp {
    width: 100%;
    height: 68px;
    border: 1px solid #334663;
    border-radius: 12px;
    margin-bottom: 22px;
}

.emailInputModal .inp:focus-within {
    border-color: #9C94FF;
}

.emailInputModal .inp input {
    width: 100%;
    height: 100%;
    outline: none;
    border: 0;
    background: transparent;
    padding-left: 30px;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
}

.emailInputModal .confrim {
    width: 100%;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #9C94FF;
    border-radius: 12px;
    font-size: 17px;
    font-weight: 600;
    color: #000000;
}

/* settings modal */
.settingsModal {
    --min-width: 100%;
    --min-height: 100%;
}

.settingsModal .settingsModal_top {
    width: 100%;
    display: flex;
    background-color: #000000;
    height: 48px;
    justify-content: space-between;
    align-items: center;
}


.settingsModal_top>div {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
}

.settingsModal_top>div:first-child {
    padding-left: 16px;
}

.settingsModal_top span {
    flex: 7;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.settingsModal .lists {
    background: transparent;
}

.settingsModal .lists ion-item {
    width: 100%;
    --background: #22242a !important;
    --padding-top: 4px;
    --padding-bottom: 4px;
    --padding-start: 16px;
    --padding-end: 16px;
    --inner-padding-end: 0;
    margin-bottom: 4px;
}

.settingsModal .lists ion-item>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff !important;
}

.settingsModal .lists ion-toggle {
    --ion-color-success: #9C94FF;
}

/* ion-list.lists {
    --ion-background-color:#101A25;
}
ion-list.lists .item-native::part(native) {
    --ion-background-color: #101A25 !important;
} */