/* ion-modal.jackmodal {
    --border-radius: 0;
    --border: none;
    --backdrop-opacity: 0;
    border: none;
    --ion-font-family: Open;
}

.jackmodal ion-content {
    --background: #101A25 !important;
    --color: #FFFFFF;
    --padding-top: 32px;
    --padding-start: 0;
    --padding-end: 0;
    --border: none !important;
    border: none;
} */
ion-modal.jackmodal{
    padding-bottom: 0 !important;
}
.jackmodal ion-content::part(scroll) {
    --border: none !important;
    border: none;
}

.jackmodal ion-content::part(scroll)::before {
    display: none;
}

.ticketModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.ticketModal .top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ticketModal .top svg {
    width: 20px;
    height: 20px;
}

.ticketModal .ok {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 14px; */
    margin-bottom: 24px;
}

.ticketModal>p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.ticketModal .mySwiper {
    width: 100%;
    margin-bottom: 46px;
}

.ticketModal .mySwiper .oneticket {
    width: 100%;
    padding: 0 20px;

}

.ticketModal .mySwiper .one {
    position: relative;
    width: 100%;
}

.ticketModal .mySwiper .one>img:first-child {
    width: 100%;
    border-radius: 15px 15px 0 0;
}

.ticketModal .mySwiper .one>img:nth-child(2) {
    position: absolute;
    height: auto;
    width: 100%;
    bottom: -6%;
    left: 0;
}

.ticketModal .mySwiper .one .circleline {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: -16px;
}

.ticketModal .mySwiper .one .circleline>div {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #101a25;
    /* -webkit-filter: blur(2px);
    filter: blur(2px); */
}

.ticketModal .mySwiper .one .circleline .margin {
    width: 32px;
    height: 32px;
}

.ticketModal .mySwiper .one .jackpot {
    width: 100%;
    position: absolute;
    left: 0;
    top: 20px;
    text-align: center;
}

.ticketModal .mySwiper .one .jackpot span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20.5955px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 0px 32.5424px rgba(0, 0, 0, 0.1);
}

.ticketModal .mySwiper .one .jackpot p {
    font-family: 'Orelega One';
    font-style: normal;
    font-weight: 400;
    font-size: 82.1522px;
    line-height: 90px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 0px 32.5424px rgba(0, 0, 0, 0.1);
}

.ticketModal .mySwiper .bottom {
    width: 100%;
    height: 122px;
    background: #FFFFFF;
    border-radius: 0 0 15px 15px;
    padding-left: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ticketModal .mySwiper .bottom>p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.216949px;
    color: #000000;
    text-shadow: 0px 0px 32.5424px rgba(0, 0, 0, 0.1);
}

.ticketModal .mySwiper .bottom>p>span {
    font-weight: 600;
}

.ticketModal .done {
    /* margin-top: 20px; */
    width: 100%;
    height: 48px;
    background: #9C94FF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #000000;
}

.ticketModal .split_ticket {
    width: 100%;
    height: 48px;
    color: #9C94FF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
}

.mySwiper {
    overflow: visible;
    --swiper-pagination-bottom: -10%;
    --swiper-pagination-bullet-inactive-color: gray;
}