@font-face {
    font-family: open;
    src: url('../style/font/Open-Sans-2.ttf');
}

@keyframes progressWidth {
    0% {
        width: 0;
    }

}

p {
    margin: 0
}

.ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    align-items: center;
}

.billion {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-family: open;
}

.icon {

    display: flex;
    align-items: center;
    height: 52px;
    width: 100%;

    padding: 0 16px;
    position: fixed;
    top: 0;
    background: #101A25;
}

.icon div {
    cursor: pointer;
}

.isRingActive {
    position: relative;
    margin-left: 20px;
}

.isRingActive div {
    position: absolute;
    top: 0;
    right: -6px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #9C94FF;
}

.ims {
    position: absolute;
    right: 157px;
}

.p {
    position: absolute;
    right: 126px;
    width: 25px;
    height: 10px;
    font-style: normal;
    font-size: 12px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.svg {
    position: absolute;
    right: 98px;
}

.span {
    position: absolute;
    right: 24px;
    width: 66px;
    height: 10px;
    font-style: normal;
    font-size: 12px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.main {
    padding-top: 52px;
    height: auto;
    width: 100%;
    background-color: #101A25;

}

.movie {
    display: flex;
    width: 100%;
    height: auto;
    position: relative;
}

.movieimg {
    position: absolute;
    top: 7%;
    left: 65%;
    z-index: 10;
    width: 116px;
    height: 16px;
}

.movie img:nth-child(1) {
    width: 100%;
    height: auto;
    border: none;
    vertical-align: top;
}

.moviespan {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 28%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: open;
    font-style: normal;
    font-weight: 700;
    font-size: 27.7677px;
    line-height: 38px;
    color: #FFFFFF;
    text-shadow: 0px 2px 16px rgba(0, 0, 0, 0.4);
    z-index: 10;
    letter-spacing: 2px;
}

.moviespan1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 50%;
    font-family: open;
    font-style: normal;
    font-weight: 700;
    font-size: 75.94px;
    line-height: 103px;
    color: #FFFFFF;
    text-shadow: 0px 2px 16px rgba(0, 0, 0, 0.4);
    z-index: 10;
    text-align: center;
}

.bottom {
    z-index: 10;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 75px;
    margin-top: 10px;

}

.texsbox {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 94px;
    top: -102px;
    background: rgba(16, 26, 37, 0.8);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    padding: 0 16px;
}

.texstop {
    margin-top: 14px;
    box-sizing: border-box;
    height: 24px;
    margin-bottom: 7px;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    font-family: open;
}

.progrebox {
    width: 100%;
    height: 5px;
    background: #2D323E;
    border-radius: 12px;
    margin-top: 5px;
}

.progress {
    height: 5px;
    /* Special/grad */
    background: linear-gradient(270.17deg, #7190FF 0.15%, #AC8FFF 99.88%);
    border-radius: 12px;
    transition: all 2s linear;
    animation: progressWidth 2s linear;

}

.textmain div {
    width: 70%;
    height: 7px;
    background: linear-gradient(270.17deg, #7190FF 0.15%, #AC8FFF 99.88%);
    border-radius: 12px;
}

.textbottom {
    display: flex;
    width: 90%;
    height: 18px;
    margin-top: 10px;
    align-items: center;
}

.texthead {
    display: flex;
    height: 18px;
    font-family: open;
}

.texthead img {
    width: 16px;
    height: 16px;
    border-radius: 100%;
}

.textbottom span {
    height: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #b6b6b6;
    font-family: open;
    margin-right: 5px;
    margin-left: 5px;
}

.ul li {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    color: #ffffff;
    padding: 8px;
}

.lien {
    width: 98%;
    height: 2px;
    background: #334663;
    border-radius: 0.5px;
    margin: 50px 10px 0px 8px;
}

.ul li span {
    position: absolute;
    left: 53px;
    width: 200px;
    height: 24px;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: open;
}

.img5 {

    position: absolute;
    left: 23px;
}

.img6 {
    position: absolute;
    right: 23px;
}

.span1 {
    font-family: open;
    position: absolute;
    top: 123px;
}

.carsLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 16px;
}

.carsLine .carsAnd {
    display: flex;
    align-items: center;
}

.carsLine .carsAnd span {
    font-weight: 400;
    font-size: 8px;
    color: #CCCCCC;
    margin-right: 12px;
}

.carsLine .carsAnd img {
    margin-right: 12px;
    height: 38px;
}

.carsLine .howit {
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #9C94FF;
}

.cards {
    width: 100%;
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
    padding-bottom: 80px;
}

.cards li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    padding: 8px 0 20px;
    position: relative;
    flex: 1;
}

.cards li:last-child {
    margin-right: 0;
}

.cards li .mains {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 144px;
    background: #2D323E;
    /* border: 1px solid #42E0A7; */
    border-radius: 8px;
    padding-bottom: 10px;
    position: relative;
}

.cards li .mains>span {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #FFFFFF;
    margin-bottom: 4px;
}

.cards li .mains>p {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
}

.cards li .mains .joined {
    position: absolute;
    bottom: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    color: #42E05C;
}

.cards li .mains .dice {
    position: absolute;
    bottom: 4px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;
}

.cards li .mains .dice>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.cards li .tojoin {
    position: absolute;
    bottom: -8px;
}

.cards li .popular {
    position: absolute;
    top: 0;
    padding: 4px 16px;
    background: #42E05C;
    border-radius: 20px;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    z-index: 2;
}

.cards li .exchange {
    position: absolute;
    top: 0;
    padding: 4px 8px;
    background: #FF9950;
    border-radius: 20px;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    z-index: 2;
}