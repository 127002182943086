ion-modal.giftOptionsDetailInFriends {
  z-index: 255555 !important;
  padding-bottom: 0 !important;
}
.giftOption {
  display: flex;
  flex-direction: column;
}
.giftOption .tit {
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000;
  color: #ffffff;
  margin-bottom: 16px;
}
.giftOption .tit > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.giftOption .tit > div p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #cccccc;
}
.giftOption .tit > span {
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #9c94ff;
  padding: 0 16px;
}
.giftOption .main {
  flex: 1;
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.giftOption .main .topMain .options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin-bottom: 12px;
}
.giftOption .main .topMain .options li {
  width: 110px;
  height: 80px;
  border-radius: 12px;
  border: 1px solid #2e323e;
  background: rgba(156, 148, 255, 0.05);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.giftOption .main .topMain .options li > div {
  color: #9c94ff;
  font-family: Open;
  font-size: 32px;
  font-weight: 600;
}
.giftOption .main .topMain .options li > div span {
  color: #9c94ff;
  font-family: Open;
  font-size: 14px;
  font-weight: 400;
  margin-right: 4px;
}
.giftOption .main .topMain .options li p {
  color: #fff;
  font-family: Open;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15.5px;
}
.giftOption .main .topMain .options li:hover {
  background: #9c94ff;
}
.giftOption .main .topMain .options li:hover > div {
  color: #2d323e;
}
.giftOption .main .topMain .options li:hover > div span {
  color: #2d323e;
}
.giftOption .main .topMain .options li:hover p {
  color: #2d323e;
}
.giftOption .main .topMain .options .act {
  background: #9c94ff;
}
.giftOption .main .topMain .options .act > div {
  color: #2d323e;
}
.giftOption .main .topMain .options .act > div span {
  color: #2d323e;
}
.giftOption .main .topMain .options .act p {
  color: #2d323e;
}
.giftOption .main .topMain .amount {
  margin-top: 28px;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 16px;
  position: inherit;
}
.giftOption .main .topMain .amount input {
  width: 100%;
  height: 67px;
  background: #172030;
  outline: none;
  border-radius: 12px;
  border: 2px solid #2e323e;
  padding-left: 22px;
  color: #9c94ff;
  font-family: Open;
  font-size: 20px;
  font-weight: 600;
}
.giftOption .main .topMain .amount input::placeholder {
  color: #334663;
  font-family: Open;
  font-size: 16px;
  font-weight: 400;
}
.giftOption .main .topMain .amount input:focus-within {
  border: 2px solid #9c94ff;
}
.giftOption .main .topMain .amount .err {
  padding-left: 16px;
  margin-top: 8px;
  color: #ff6881;
  font-family: Open;
  font-size: 16px;
  font-weight: 400;
}
.giftOption .main .topMain .points {
  width: 100%;
  height: 78px;
  padding: 0 16px;
  margin-bottom: 22px;
}
.giftOption .main .topMain .points > div {
  width: 100%;
  height: 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: #012f49;
}
.giftOption .main .topMain .points > div p {
  max-width: 60%;
  color: #6296b6;
  font-family: Open;
  font-size: 16px;
  font-weight: 400;
}
.giftOption .main .topMain .points > div div {
  display: flex;
  align-items: center;
}
.giftOption .main .topMain .points > div div svg {
  margin-right: 4px;
}
.giftOption .main .topMain .points > div div span {
  color: #fff;
  font-family: Open;
  font-size: 16px;
  font-weight: 400;
}
.giftOption .main .topMain .learnMore {
  width: 100%;
  padding: 0 16px;
  color: #fff;
  font-family: Open;
  font-size: 14px;
  font-weight: 400;
}
.giftOption .main .topMain .learnMore span {
  margin-left: 4px;
  color: #9c94ff;
}
.giftOption .main .payBtn {
  margin-top: 20px;
  padding: 0 16px;
}
.giftOption .main .payBtn > div {
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #9c94ff;
  flex-shrink: 0;
}
.giftOption .main .payBtn > div p {
  height: 16px;
  color: #000;
  font-family: Open;
  font-size: 14px;
  font-weight: 600;
}
.giftOption .main .payBtn > div span {
  margin-left: 8px;
  color: #000;
  font-family: Open;
  font-size: 22px;
  font-weight: 600;
}
