.emoji-picker {
  ul {
    padding: 4px 8px;
    display: flex;
    border-radius: 30px;
    background-color: #000000;
    gap: 2px;
    li {
      text-align: center;
      line-height: 40px;
      width: 40px;
      height: 40px;
      font-size: 16px;
      border-radius: 50%;
      &[class*='active'] {
        background-color: #003999;
      }
    }
  }
}

.emoji-comment {
  ul {
    gap: 2px;
    display: flex;
    li {
      display: flex;
      background-color: #585155;
      border-radius: 6px;
      padding: 0 4px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      font-size: 14px;
      span {
        color: #fff;
      }
    }
  }
}
