.inviteSplitPopUp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 25556;
}
.inviteSplitPopUp .main {
  width: 274px;
  padding: 74px 22px 32px;
  border-radius: 12px;
  background: linear-gradient(180deg, #edecff 0%, #fff 100%);
  position: relative;
}
.inviteSplitPopUp .main > img {
  position: absolute;
  left: calc(50% - 66.5px);
  top: -71px;
}
.inviteSplitPopUp .main .name {
  color: #736bd1;
  text-align: center;
  font-family: Open;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.inviteSplitPopUp .main .description {
  color: #101a25;
  font-family: Open;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.inviteSplitPopUp .main .info {
  margin-top: 18px;
  margin-bottom: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inviteSplitPopUp .main .info span {
  color: #101a25;
  text-align: center;
  font-family: Open;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 4px;
}
.inviteSplitPopUp .main .info p {
  color: #cfa668;
  text-align: center;
  font-family: Open;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.inviteSplitPopUp .main .acts {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inviteSplitPopUp .main .acts .accept {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 26px;
  border-radius: 8px;
  border: 1px solid #9c94ff;
  background: #9c94ff;
  color: #000;
  font-family: Open;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 18px;
}
.inviteSplitPopUp .main .acts .reject {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 26px;
  border-radius: 8px;
  border: 1px solid #9c94ff;
  color: #9c94ff;
  font-family: Open;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.inviteSplitPopUp .main .close {
  position: absolute;
  bottom: -44px;
  left: calc(50% - 12px);
}
.inviteSplitPopUp .mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
}
