
.count-number {
  height: 20px;
  display: flex;
  overflow: hidden;
  text-align: center;
}

.count-up {
  width: 10px;
}

.count-up > div {
  font-size: 14px;
  width: 10px;
  color: #CCCCCC;
  height: 20px;
  line-height: 20px;
  margin-right: 10px;
}
