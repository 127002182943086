@font-face {
    font-family: Orelega One;
    src: url(../style/font/OrelegaOne-Regular.ttf);
}

@keyframes progressWidth {
    0% {
        width: 0;
    }

}

@keyframes countup {
    0% {
        transform: translateY(10px);
    }

}

@font-face {
    font-family: gilory;
    src: url(../style/font/gilroy-bold-4.otf);
}

.upcoming {
    /* height: calc(100vh - 83px); */
    flex: 1;
    padding-bottom: 82px;
    /* display: flex;
    flex-direction: column; */
    padding-top: 52px;
}

.movie {
    position: relative;
    width: 100%;
    height: auto;
}

.movie>div>div>button {
    display: none !important;
}

.vido {
    width: 100%;
    height: 100%;
}

.winningvido {
    height: 100%;
    width: 100%;

}

.movie img {
    width: 100% !important;
    height: auto;
}

.lists {
    width: 100%;
}

.change {
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
    height: 48px;
}

.change svg {
    cursor: pointer;
}

/* .change p {
    font-family: Open;
    font-size: 16px;
    font-weight: 600;
    margin-right: 8px;
    cursor: pointer;
} */

.line {
    width: calc(100% - 16px);
    height: 1px;
    background-color: #334663;
    margin-bottom: 12px;
    margin-left: auto;
}

.cards {
    list-style: none;
    padding: 0 8px 190px;
    background: transparent;
}

.cards ion-item {
    --background: transparent;
    --color: #ffffff;
    --inner-border-width: 0;
    --inner-padding-end: 0;
    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 8px;
    --padding-bottom: 8px;
    /* display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
    border-radius: 10px; */
    /* border: 1px solid #3f3f3f; */
}

.cartex {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 120px;
}

.cartex p {

    /* width: 165px; */
    height: 22px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #A6A9B8;
    margin-top: 25px;
}

.cartex img {
    margin-top: 60px;
}

.cards li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    color: #ffffff;
    padding: 8px;
}

.cards .left {
    display: flex;
    align-items: center;
    flex: 1;
    padding-right: 20px;
}

.left .leftimg {
    width: 100px;
    height: 70px;
    display: flex;
    justify-content: center;
}

.leftimg img {
    width: 100%;
    height: auto;
    border-radius: 12px;
    object-fit: cover;
    object-position: top;
}

.cards .detail {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 12px;
}

.cards .detail p:nth-child(1) {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
}

.cards .detail p:nth-child(1)>span {
    display: flex;
    align-items: center;
    margin-left: 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #F29B19;
}

.cards .detail p:nth-child(1)>span img {
    width: 14px;
    height: 14px;
}

.cards .detail p:nth-child(2) {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 4px;
}

.cards .detail .justJoin {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;

}

.cards .detail .justJoin svg {
    margin-right: 4px;
}

.cards .detail .friends {
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.cards .detail .friends img {
    height: 16px;
    margin-right: 4px;
}

.cards .detail>img {
    margin-bottom: 8px;
    height: 5px;
    width: auto;
}

.cards .join {
    width: 44px;
    height: 44px;
    background-color: #9C94FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;
}



.dice {
    width: 44px;
    height: 44px;

    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;
}

.dice>img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}

.cards .lijoined {
    border-radius: 10px;
    background-color: #3f3f3f;
    /* padding: 8px 0; */
}

.cards .lijoined .joinedicon {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #42E05C;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}

.cards .lijoined .joinedicon svg {
    margin-bottom: 6px;
}

/* cannel change  */
.Channemodal {
    width: 100%;
    color: #ffffff;
    --min-height: 100%;
    --min-width: 100%;

}

.addmodal {
    --min-height: 100%;
    --min-width: 100%;
}

.Channemodal .channel {
    color: #FFFFFF;
    padding: 22px 16px 0;
}

.Channemodal ion-content::part(scroll) {
    background: #2D323E;
}

.Channemodal ion-content::part(scroll)::before {
    display: none;
}

.Channemodal .channel li {
    font-size: 16px;
    font-weight: 600;
    font-family: Open;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}

.Channemodal .channel li span {
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: transparent;
    margin-right: 8px;
}

.Channemodal .channel li .act {
    background: #9C94FF;
}

.optionmodal {
    --min-height: 100%;
    --min-width: 100%;
}

.ticketmodal {
    --min-height: 100%;
    --min-width: 100%;
}

/* .jackmodal {
    --height: 428px;
    --width: 295px;
    --backdrop-opacity: 0.3;
    --background: url('../image/upcoming/Group\ 2203.png');
} */

.confirmlogin {
    --height: 428px;
    --width: 295px;
    --backdrop-opacity: 0.5;
    --background-color: #ffffff;
}

.winningmodal {
    --min-width: 100%;
    --min-height: 100%;
    /* --height: 428px;  */
    /* --width: 295px; */

    --backdrop-opacity: 0.3;
    /* position: relative; */
    /* width: 400px;
    height: 200px; */
}

.winningmodal p {
    position: absolute;
    top: 10px;
    left: 45%;
}

.wrapper {
    width: 100%;
    height: 100%;

}

.wrapper span {
    position: absolute;
    height: 24px;
    font-family: Open Sans;
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
    color: #FFFFFF;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    right: 8px;
    top: 4px;


}

.p {
    position: absolute;
    top: 115px;
    left: 111px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 18.9865px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

}

.mun {
    font-family: Orelega One;
    /* Orelega One */
    width: 250px;
    position: absolute;
    left: 24px;
    top: 141px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    height: 40px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.055em;
    font-size: 30px;

}

.Tick {

    width: 198.29px;
    height: 24px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 54px;
    left: 21px;
}

.Tick span {
    position: absolute;
    left: 92px;
    top: 0px;
    font-weight: 600;
    width: 200px;
    text-align: left;
    margin-left: 34px;
}

.Owner {
    width: 198.29px;
    height: 24px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 22px;
    left: 21px;
}

.Owner span {
    font-weight: 600;
    position: absolute;
    top: -1px;
    left: 55px;
    width: 200px;
    text-align: left;
    margin-left: 10px;
}




.progress {
    height: 5px;
    /* Special/grad */
    background: linear-gradient(270.17deg, #7190FF 0.15%, #AC8FFF 99.88%);
    border-radius: 12px;
    transition: all 2s linear;
    animation: progressWidth 2s linear;

}

.joined {

    height: 5px;
    transition: all 2s linear;
    animation: progressWidth 2s linear;

    /* Special/grad_green */

    background: linear-gradient(270.17deg, #5CE46A 0.15%, #2EA51B 99.88%);
    border-radius: 12px;
}

.progrebox {
    width: 100%;
    height: 5px;
    background: #2D323E;
    border-radius: 12px;
}


.pinsmain {
    width: 100%;
    /* margin-top: 200px; */
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    /* justify-content: center; */
}

.pinsmain .pinDes div {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 12px;
}

.pinsp {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #48596C;
    margin-bottom: 20px;
}

.pinsul {
    position: relative;
    width: 100%;
    padding: 0 39px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 64px;
}

.pinsul li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    border: 2px solid rgba(156, 148, 255, 0.45);
    border-radius: 8px;
}

.pinsul li:focus-within {
    border-color: #9C94FF;
}

.pinsul li input {
    width: 54px;
    height: 54px;
    outline: none;
    background-color: transparent;
    border: none;
    text-align: center;
    caret-color: transparent;
    font-size: 2em;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 4px;
    color: #9C94FF;
}

.pinsudiv {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10%;
    width: 50px;
    height: 35px;
    outline: none;
    background-color: #101A25;
    border: none;
    /* text-align: center; */
    /* caret-color: transparent; */
    font-size: 4em;
    font-weight: 400;
    color: #ffffff;
    /* margin-bottom: 4px; */
    color: #9C94FF;
}

.nonepinsudiv {
    display: none;
}

ion-modal.pincodeInFriends {
    padding-bottom: 0px!important;
    z-index: 255558 !important;
    padding-bottom: 0 !important;
    --min-height: 100%;
    --min-width: 100%;
}

ion-modal.pincodeNotFriends {
    padding-bottom: 0 !important;
    z-index: 255558 !important;
    --min-height: 100%;
    --min-width: 100%;
}

.pinsTop {
    position: fixed;
    top: 0;
    left: 0;
    padding-left: 16px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
}

.pinsbtn {
    width: 100%;
    height: 54px;
    background: #9C94FF;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 17px;
    color: #000000;
    flex-shrink: 0;
}

.clos {
    position: fixed;
    top: 10px;
    right: 10px;
}

.winnmodal {
    --min-height: 100%;
    --min-width: 100%;
}

.winmodalbtn {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 100%;
    height: 48px;

    /* Main/Main */

    border: 1px solid #9C94FF;
    border-radius: 8px;

    margin-top: 30px;
}

.winmodalbtn p {
    /* height: 10px; */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Btn/MediumBtn */

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
    /* identical to box height, or 71% */

    letter-spacing: 0.2px;

    /* Main/Main */

    color: #9C94FF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.winmp {
    position: absolute;
    width: 100%;
    height: 60px;
    left: 0%;
    top: calc(50% - -6px);
    ;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    /* identical to box height, or 125% */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    /* Text/Primary */

    color: #FFFFFF;
}


.winmwon {
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 24px;
    left: 0%;
    top: calc(50% - 24px);
    letter-spacing: 1px;
    /* Body/Title Bold */

    font-family: Open Sans;
    font-style: normal;

    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Text/Primary */

    color: #FFFFFF;
}

.count {
    margin-top: 7px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.coutimg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.coutimg img {
    width: 16px;
    height: 16px;
    border-radius: 100%;
}

.count span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #CCCCCC;
}

.winnmvideo {
    position: relative;
}

.winnmvideo>div>div {
    display: none !important;
}

.winnmvideo>div>button {
    display: none !important;
}

.winninNum {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;

}

.winninNum span {
    height: 80px;
    text-align: center;
    line-height: 80px;
    font-size: 47px;
    font-weight: 600;
    color: #ffc546;
    margin-right: 8px;
    font-family: gilory;
    text-shadow: -1px 3px #453111;
}


.countUp {
    width: 8px;


}

.countUp>div {
    font-size: 13px;
    color: #CCCCCC;
    width: 8px;
    height: 20px;
    line-height: 20px;
}

.changeleft {
    width: 100%;
    display: flex;
    align-items: center;
    height: 48px;
    justify-content: space-between;
}

@media (min-width: 500px) {
    .changeleft {
        width: 100%;
        display: flex;
        align-items: center;
        height: 48px;
        justify-content: flex-start;
    }

    .changeleft p {
        margin-right: 20px;
    }
}

.changeleft p {
    height: 48px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 48px;
    text-align: center;
    color: #A6A9B8;
    /* margin-right: 12px; */
}

.selected {
    border-bottom: 2px solid #9C94FF;
    height: 48px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #9C94FF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0;

}

.changesvg {
    display: flex;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;

    width: 78px;
    height: 36px;
    justify-content: space-around;

    /* Background/Background Light */

    background: #2D323E;
    border-radius: 4px;
    margin-left: 12px;

}

.changesvg span {

    height: 16px;

    font-family: Open Sans;
    font-style: normal;

    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.2px;

    /* Text/Primary */

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.changerigth {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 12px 0px;

}

.changerigth p {
    width: 59px;
    height: 18px;
    left: 16px;
    top: 389px;

    /* Body/Body1 */

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    display: flex;
    align-items: center;

    /* Text/Primary */

    color: #FFFFFF;
    margin-left: 16px;
}

.changesvgtop {
    display: none;
    position: absolute;
    top: -14px;
    left: 10px;

}

.changesvgtop1 {
    display: block;
    position: absolute;
    top: -15%;
    left: -5%;

}

.node {
    display: none;
}

.nonechangesvgtop {
    display: block;
    position: absolute;
    top: -15%;
    left: -5%;
    transform: rotate(180deg)
}


.changepath {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    width: 78px;
    height: 36px;
    justify-content: space-around;
    background: #2D323E;
    border-radius: 4px;
    margin-left: 12px;
}

.changepath span {

    height: 16px;
    font-family: Open Sans;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.winjif {
    width: 90%;
    height: 90%;
}

.moretop {
    width: 100%;
    height: 48px;
    margin-top: -16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.moresvg span {
    font-family: Open Sans;
    font-style: normal;

    font-size: 16px;
    line-height: 24px;

    color: #FFFFFF;

}

.moretopspan {
    font-family: Open Sans;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #9C94FF;
}

.more_img img {
    border: none;
    vertical-align: top;
    width: 100%;
}

.more_img {
    margin-top: 14px;
    position: relative;
}

.more_img>div {
    width: 100%;
    position: absolute;
    left: 0;
    top: 20px;
    text-align: center;
}

.more_img>div i {
    /* position: unset !important;
    width: 100% !important; */
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 20.5955px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 0px 32.5424px rgba(0, 0, 0, 0.1);
}

.more_img>div p {
    /* position: unset !important;
    width: 100% !important; */
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 76px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 2px 24px rgba(0, 0, 0, 0.5);
}

.more_imgtex {
    position: relative;
}

/* .more_img i {
    position: absolute;
    width: 312px;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 29px;
    line-height: 76px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 2px 24px rgba(0, 0, 0, 0.5);
}

.more_img p {
    position: absolute;
    width: 312px;
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 76px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 2px 24px rgba(0, 0, 0, 0.5);
}

.more_img div {
    position: absolute;
    width: 312px;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 76px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 2px 24px rgba(0, 0, 0, 0.5);
} */