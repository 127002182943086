.inviteModal {
  position: absolute;
  top: 26px;
  left: 0;
  width: 100%;
  height: 0;
  padding: 0 16px;
  z-index: 999999;
  overflow: hidden;
  transition: all 0.3s;
}
.inviteModal .main {
  width: 100%;
  position: relative;
  padding: 20px 16px;
  background: linear-gradient(0deg, #e9e8f9, #e9e8f9), linear-gradient(180deg, #e8e9ff 0%, #ffffff 100%);
  border-radius: 12px;
}
.inviteModal .main > svg {
  position: absolute;
  right: 16px;
  top: 12px;
}
.inviteModal .main .info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.inviteModal .main .info > img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 14px;
}
.inviteModal .main .info > div {
  display: flex;
  flex-direction: column;
}
.inviteModal .main .info > div span {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
.inviteModal .main .info > div p {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #2c2b3f;
}
.inviteModal .main .actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.inviteModal .main .actions .accept {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 38px;
  background: #9c94ff;
  border-radius: 10px;
  margin-right: 12px;
  font-size: 13px;
  color: #000000;
}
.inviteModal .main .actions .refuse {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: 38px;
  background: #ffffff;
  border-radius: 10px;
  font-size: 13px;
  color: #66686b;
}
.active {
  height: 158px !important;
  transition: all 0.3s;
}
