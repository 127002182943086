.giftPointsModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.giftPointsModal .modalBox {
  width: 274px;
  height: 300px;
  background: url("Subtract.png") no-repeat;
  background-size: contain;
  position: relative;
  padding-top: 52px;
}
.giftPointsModal .modalBox > img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 4px solid #fff;
  position: absolute;
  top: -36px;
  left: calc(50% - 36px);
}
.giftPointsModal .modalBox .name {
  color: #2d323e;
  font-family: Open;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}
.giftPointsModal .modalBox .amount {
  color: #2d323e;
  font-family: Open;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 4px;
}
.giftPointsModal .modalBox .points {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 10px;
}
.giftPointsModal .modalBox .points span {
  color: #000;
  font-family: Open;
  font-size: 42px;
  line-height: 42px;
  font-weight: 600;
}
.giftPointsModal .modalBox .infos {
  margin-bottom: 22px;
}
.giftPointsModal .modalBox .infos p {
  color: #aaa7c9;
  text-align: center;
  font-family: Open;
  font-size: 12px;
  font-weight: 400;
}
.giftPointsModal .modalBox .acts {
  width: 100%;
  padding: 0 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.giftPointsModal .modalBox .acts .accept {
  width: 96px;
  height: 39px;
  border-radius: 8px;
  background: #9c94ff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-family: Open;
  font-size: 14px;
  font-weight: 400;
}
.giftPointsModal .modalBox .acts .reject {
  width: 96px;
  height: 39px;
  border-radius: 8px;
  border: 1px solid #9c94ff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9c94ff;
  font-family: Open;
  font-size: 14px;
  font-weight: 400;
}
.giftPointsModal .modalBox .close {
  position: absolute;
  bottom: -50px;
  left: calc(50% - 12px);
  cursor: pointer;
}
.giftPointsModal .mask {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -1;
}
