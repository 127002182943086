/* Joined to JoinedDraws pages  css*/
@keyframes progressWidth {
    0% {
        width: 0;
    }

}

@keyframes countup {
    0% {
        transform: translateY(10px);
    }

}

ion-modal .modal-wrapper {
    position: absolute;
    display: block;
    min-height: 400px;
    height: auto;
}

ion-modal,
ion-modal ion-backdrop {
    pointer-events: none;
    width: 100%;
}

.ion-padding {
    width: 100%;
    background-color: #42E05C;
}

.joinHeader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 4%;

}

.joinHeader p {
    font-size: 16px;
    color: #FFF;
    margin-bottom: 10px;
}

.joinHeader .optionBtn {
    --background: none;
    --box-shadow: 0;


}

.joinHeader .sp {
    color: #9C94FF;
    text-transform: none !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.2px;
    order: 1;
    align-self: stretch;
}

.joinbox {
    width: 100%;
    padding: 27px 0 10px 0;
}

.joinbox span {
    font-size: 14px;
    color: #FFF;
    padding-left: 5px;
}

.joinbox1 {
    width: 100%;
    padding: 27px 0 10px 0;
}

.joinbox1 span {
    font-size: 14px;
    color: #FFF;
    padding-left: 5px;
}

.joinboxnone {
    display: none;
}

.joinConst {
    position: relative;
    width: 92%;
    height: 48px;
    border: 1px solid #334663;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0px;

}

.joinConst1 {
    margin-top: 5px;
    position: relative;
    width: 100%;
    height: 48px;
    border: 1px solid #334663;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.joinConst .beting {
    display: flex;
    color: #FFF;
    flex-direction: column;
    align-items: center;
    color: #FFF;
}

.joinConst1 .beting {
    display: flex;
    color: #FFF;
    flex-direction: column;
    align-items: center;
    color: #FFF;
}

.joinConst .fontsize1 {
    font-size: 12px;
}

.joinConst1 .fontsize1 {
    font-size: 12px;
}

.joinConst .winning {
    color: #42E05C;
}

.joinConst1 .winning {
    color: #42E05C;
}

.joinConst .fontsize2 {
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.joinConst1 .fontsize2 {
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

/* cardbox */

.cardbox ul {
    list-style: none;
    width: 90%;
    margin-left: 4%;
}

.cards {
    position: relative;
}

.cardbox .cards li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    color: #ffffff;
    /* padding: 8px; */
}

.cards li .left {
    width: 100%;
    margin-top: 10px;
    position: relative;
}

.cardbox .cards li .left img {
    width: 100%;
    height: auto;

}

.left .leftimg {
    border: none;
    vertical-align: top;
    width: 100%;
    transform: scale(1.3, 1);
}

.more_box {
    padding-bottom: 50px;
}

.detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -62px;
    background: rgba(16, 26, 37, 0.8);
}

.detail p {
    margin-top: 14px;
    box-sizing: border-box;
    height: 24px;
    margin-bottom: 7px;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    font-family: Open Sans;

}


.cardbox .cards li .detail .friends {
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    height: 16px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #CCCCCC;
}

.cardbox .cards li .detail .friends img {
    height: 16px;
    margin-right: 4px;
}

.cardbox .cards li .detail>img {
    margin-bottom: 8px;
    height: 5px;
    width: auto;
}

.cardbox .cards li .join {
    width: 44px;
    height: 44px;
    background-color: #9C94FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;
}

.lijoined {
    position: relative;
}

.lidisplay {
    position: relative;
}

.cardbox .cards .lijoined {
    border-radius: 10px;
    background-color: #2D323E;
}

.cardbox .cards .lijoined .joinedicon {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #42E05C;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}

.cardbox .cards .lijoined .joinedicon svg {
    margin-bottom: 6px;
}


.cardbox .cards li {
    display: none;
}

.cardbox .cards .lidisplay {
    display: block;
}

.svg {
    margin: 13px 11px 3px 10px;

}

.confirm {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.confirm .confirmBtn {
    height: 48px;
    width: 100%;
    --background: #9C94FF;
    --color: #000;
    --border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
    letter-spacing: 0.2px;
    text-transform: none !important;
}

.confirm .confirmBtn1 {
    height: 48px;
    width: 100%;
    --background: #9C94FF;
    --color: #000;
    --border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
    letter-spacing: 0.2px;
    text-transform: none !important;
}

/* Join game Radio */
ion-gradio-group {
    display: flex;
    justify-content: space-around;
    align-items: center;
    --color: #FFFFFF;
}

ion-radio {
    --border-radius: 10px;
    --inner-border-radius: 10px;

    --color: #FFFFFF;
    --color-checked: #9C94FF;
    color: #FFFFFF;
}

ion-radio.ios {
    width: 20px;
    height: 20px;

    border: 2px solid #FFFFFF;
    border-radius: 4px;
    --color: #FFFFFF;

}

.radio-checked.ios {
    border-color: #9C94FF;
    --color: #FFFFFF;

}

.radioBox {
    display: flex;
    align-items: center;
    margin: 8px 0;
}

.radioBox ion-radio {
    margin: 0;
}

.radioBox ion-item {
    --background: transition;
    --background-activated-opacity: 0;
    --border-color: #101A25;
    /* --border-width: 0; */
    margin: 0;
    /* margin-bottom: 16px; */
    --color: #FFFFFF;
    --min-height:40px;
}
.radioBox ion-item::part(native){
    padding: 0 !important;
}
.radioBox ion-label {
    margin: 0;
    margin-left: 10px;
    /* -webkit-mask-image: none !important; */
    -webkit-font-smoothing: antialiased
}

/*
.radioBox span {
    color: #ffffff;
    font-size: 16px;
}

.radioBox div {
    width: 400px;
    height: auto;
    padding: 5% 8%;
    display: flex;
    align-items: center;
    --background: #101A25;
}

.radioBox span {
    margin-left: 10px;
} */

.close {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    background: #2D323E;
    border-radius: 8px;
    color: #ffffff;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
    letter-spacing: 0.2px;
    margin-top: 30px;
}

.progrebox {
    width: 100%;
    height: 7px;
    background: #2D323E;
    border-radius: 12px;

}

.progrebox .progress {
    height: 7px;
    background: linear-gradient(270.17deg, #7190FF 0.15%, #AC8FFF 99.88%);
    border-radius: 12px;
    transition: all 2s linear;
    animation: progressWidth 2s linear;
}

.countUp {
    width: 10px;
    animation: countup 2s linear;
}

.countUp>div {
    font-size: 14px;
    color: #CCCCCC;
    width: 10px;
    height: 20px;
    line-height: 20px;


}