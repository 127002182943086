@font-face {
    font-family: Open;
    src: url('./font/Open-Sans-2.ttf');
}

html,
body {
    padding: 0;
    margin: 0;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

img {
    border: none;
    vertical-align: top;
}

a {
    text-decoration: none;
}

p {
    margin: 0;
}

.alexBlocks {
    /* height: 100vh !important; */
    /* display: flex !important;
    flex-direction: column !important; */
    font-family: Open !important;
    background-color: #101A25 !important;
    text-transform: none !important;
}

.mainmain {
    flex: 1 !important;
}

ion-modal {
    --ion-font-family: Open !important;
    padding-bottom: 83px !important;

}

ion-content {
    --keyboard-offset: 0 !important;
}

.select-action-sheet {
    z-index: 25555 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.my-custom-class {
    --backdrop-opacity: 0.2;
    --background: #0F1A25;
    --spinner-color: #9C94FF;
    color: #9C94FF;
    font-weight: 400;
    z-index: 999999 !important;
}

ion-toast {
    --background: rgb(229, 200, 244);
    --color: #000000;
    /* font-size: larger; */
    --border-radius: 0;
    --min-width: 100%;
    --start: 0;
    z-index: 9999999 !important;
}

.marquee {
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
    top: 52px;
    width: 100%;
    z-index: 9;
    color: #FFFFFF;
    background: rgba(0, 0, 0, .6);
}

.marquee div {
    display: inline-block;
    padding-left: 100%;
    /* animation: marquee 15s linear infinite; */
    animation-name: marquee;
}

.marquee div span {
    margin-right: 20px;
}

.marquee div span:last-child {
    margin-right: 0;
}


@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

* {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

*::-webkit-scrollbar {
    display: none;
}

.alert-button {
    font-weight: 400 !important;
}

.swiper-pagination {
    width: 84% !important;
    left: 7% !important;
}

.select-action-sheet {
    --button-color: #fff;
    --background: #2D323E;
    --button-background: #2D323E;
    --button-background-selected: #2D323E;
    --button-color-selected: #9C94FF;
}

.action-sheet-cancel {
    font-weight: 400 !important;
}

.str-chat {
    background-color: #101A25;
}

.str-chat__message-action-icon {
    width: 16px;
    height: 16px;
}

.str-chat__suggestion-list-container {
    bottom: 80% !important;
}

.str-chat__message-actions-box:not(.str-chat__message-actions-box-angular):not(.str-chat__message-actions-box--mine) {
    /* inset-inline: unset !important; */
    left: 0 !important;
    width: fit-content !important;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    10% {
        transform: translateX(-10px);
    }

    20% {
        transform: translateX(10px);
    }

    30% {
        transform: translateX(-10px);
    }

    40% {
        transform: translateX(10px);
    }

    50% {
        transform: translateX(-10px);
    }

    60% {
        transform: translateX(10px);
    }

    70% {
        transform: translateX(-10px);
    }

    80% {
        transform: translateX(10px);
    }

    90% {
        transform: translateX(-10px);
    }

    100% {
        transform: translateX(0);
    }
}

.str-chat__li {
    padding-inline: 0 !important;
    --str-chat__own-message-reaction-background-color: rgba(255, 209, 194, 0.3);
    --str-chat__font-family: Open !important;
}

.str-chat__message-reaction {
    margin-right: 4px;
}

.str-chat__message {
    padding: 0 16px;
}

.str-chat__message-reaction-count {
    color: #FFFFFF;
}

.upComingSwiperDiv {
    width: 100%;
    position: relative;
}

.upComingSwiperDiv>img {
    width: 100% !important;
}

.upComingSwiperMain {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8vw;
}

.upComingSwiperMainleft {
    display: flex;
    flex-direction: column;
    color: #fff;
    /* margin-right: 16vw; */
    align-items: center;
}

.upComingSwiperMainleft>span {
    font-size: 6vw;
    font-family: Open;
    font-weight: 700;
}

.upComingSwiperMainleft>p {
    font-size: 6vw;
    font-family: Open;
    font-weight: 700;
}

.upComingSwiperMainleft>div {
    position: relative;
    width: 44vw;
    height: 9vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upComingSwiperMainleft>div>img {
    position: absolute;
    /* color: #fff; */
    /* z-index: -1; */
    left: 0;
    top: 0;
}

.upComingSwiperMainleft>div>p {
    z-index: 9;
    height: 7vw;
    font-size: 5vw;
}

.upComingSwiperMainRight {
    flex-shrink: 0;
    position: relative;
    width: 25vw !important;
    height: 25vw !important;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

 .ImgComponent {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* overflow: hidden; */
}

.ImgComponent>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

/* 1 member */
.ImgComponent .ImgComponentIMG2 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.ImgComponent .ImgComponentIMG2>img {
    width: calc(60% + 8px) !important;
    height: calc(60% + 8px) !important;
    border-radius: 50%;
    border: 4px solid rgb(12, 70, 208);

}

.ImgComponent .ImgComponentIMG2>img:nth-child(1) {
    z-index: 1;
}

.ImgComponent .ImgComponentIMG2>img:nth-child(2) {
    margin-left: calc(-20% - 8px);
    z-index: 2;
}

/* 2 member */
.ImgComponent .ImgComponentIMG3Img {
    width: calc(50% + 8px) !important;
    height: calc(50% + 8px) !important;
    border-radius: 50%;
    z-index: 1;
    flex-shrink: 0;
    margin-bottom: calc(-4% - 16px);
    border: 4px solid rgb(12, 70, 208);
}

.ImgComponent .ImgComponentIMG3ImgList {
    margin-left: calc(25% - 4px) !important;
}

.ImgComponent .ImgComponentIMG3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}


.ImgComponent .ImgComponentIMG3>img {
    flex-shrink: 0;
    width: calc(50% + 8px) !important;
    height: calc(50% + 8px) !important;
    border: 4px solid rgb(12, 70, 208);
    border-radius: 50%;
}

.ImgComponent .ImgComponentIMG3>img:nth-child(1) {
    z-index: 2;
}

.ImgComponent .ImgComponentIMG3>img:nth-child(2) {
    margin-left: calc(-6% - 16px);
    z-index: 3;
}

/* 3 member */
.ImgComponent .ImgComponentIMG4Top {
    margin-top: 4px;
    margin-bottom: calc(-8% - 16px);
}

.ImgComponent .ImgComponentIMG4Top,
.ImgComponent .ImgComponentIMG4Bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}


.ImgComponent .ImgComponentIMG4Top>img,
.ImgComponent .ImgComponentIMG4Bottom>img {
    flex-shrink: 0;
    width: calc(50% + 8px) !important;
    height: calc(50% + 8px) !important;
    border: 4px solid rgb(12, 70, 208);
    border-radius: 50%;
}

.ImgComponent .ImgComponentIMG4BottomText {
    flex-shrink: 0;
    width: calc(12.5vw + 8px) !important;
    height: calc(12.5vw + 8px) !important;
    border: 4px solid rgb(12, 70, 208);
    border-radius: 50%;
    background: #9C94FF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
}

.ImgComponent .ImgComponentIMG4BottomListText {
    flex-shrink: 0;
    width: calc(22px + 8px) !important;
    height: calc(22px + 8px) !important;
    border: 4px solid rgb(12, 70, 208);
    border-radius: 50%;
    background: #9C94FF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: calc(8% - 16px);
    z-index: 4;
    font-size: 10px;
}

.ImgComponentIMG4ListTop {
    margin-top: 0 !important;
    margin-bottom: calc(12% - 16px) !important;
}

.ImgComponent .ImgComponentIMG4Top>img:nth-child(1) {
    z-index: 1;
}

.ImgComponent .ImgComponentIMG4Top>img:nth-child(2) {
    margin-left: calc(-6% - 16px);
    z-index: 2;
}

.ImgComponent .ImgComponentIMG4Bottom>img:nth-child(1) {
    z-index: 3;
}

.ImgComponent .ImgComponentIMGList>img:nth-child(2) {
    margin-left: calc(8% - 16px) !important;
}

.ImgComponent .ImgComponentIMG4Bottom>img:nth-child(2),
.ImgComponent .ImgComponentIMG4Bottom .ImgComponentIMG4BottomText {
    margin-left: calc(-6% - 16px);
    z-index: 4;
}

/* .upComingSwiperMainRight>img {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
} */

.upComingSwiperMainRight .upComingSwiperCrown {
    position: absolute;
    right: 0;
    top: -3vw;
}

.upComingSwiperMainRight .upComingSwiperCrown>img {
    width: 8vw !important;
    height: 6.6vw !important;
}


.str-chat__list-notifications {
    display: none;
}


.str-chat__list-notifications {
    display: none;
}