ion-modal.giftResultInFriends {
  z-index: 255555 !important;
  padding-bottom: 0 !important;
}
.giftResultBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
}
.giftResultBox .giftResultsvg {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.giftResultBox .giftResultsvg p {
  height: 24px;
  font-family: Open Sans;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 24px;
}
.giftResultBox .giftResultsvg .giftResultp {
  height: 48px;
  font-family: Open;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #c7c7c7;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 16px;
}
.giftResultBox .giftResultbtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 48px;
  background: #9c94ff;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 70px;
}
.giftResultBox .giftResultbtn p {
  height: 16px;
  font-family: Open;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #000000;
}
